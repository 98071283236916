@import "@/scss/variables";

.appLayout {
  width: 100%;
  min-height: 100vh;
  background: $gray-0;
  display: flex;
  flex-direction: column;

  @media (min-width: $mobileBreakpoint) {
    display: grid;
    grid-template: auto 1fr / 6rem 1fr;
  }

  @media (min-width: 1200px) {
    height: 100vh;
    overflow: hidden;
  }

  .navbar {
    grid-row: span 2;
  }
}

.main {
  flex-direction: column;
  flex-grow: 1;
  display: flex;
  overflow: hidden;
}
