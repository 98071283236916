@import "@/scss/variables";

$_indicatorSize: 2.5rem;
$_indicatorMargin: 0.25rem;
$_indicatorWidth: $_indicatorSize + 2 * $_indicatorMargin;

@keyframes pulse {
  from {
    opacity: 0.5;
  }

  to {
    opacity: 1;
  }
}

@each $color, $value in $theme-colors {
  .#{$color} {
    color: $value;

    .progressBar::before {
      background: $value;
      background: linear-gradient(
        to right,
        $value 0%,
        $value calc(var(--progress) - 1%),
        map-get($theme-colors-alt, $color) calc(var(--progress) - 1%),
        map-get($theme-colors-alt, $color)
      );
    }

    .indicator {
      color: $value;
      background: map-get($theme-colors-alt, $color);
    }

    .progressBar.isFinished .indicator {
      color: #fff;
      background: $value;
    }
  }
}

.content {
  display: flex;
  align-items: center;
  font-size: 1rem;

  span {
    white-space: nowrap;

    &:first-child {
      margin-right: 1rem;
    }

    &:last-child {
      margin-left: 1rem;
    }
  }
}

.progressBar {
  --progress: 50%;

  display: flex;
  flex: 1 1 100%;
  align-items: center;
  position: relative;
  min-height: $_indicatorSize;

  &::before {
    content: "";
    width: 100%;
    height: 0.375rem;
    display: block;
    border-radius: 1rem;
  }

  &.inProgress .indicator {
    animation: pulse 1s alternate infinite;
  }

  .indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    min-width: $_indicatorSize;
    max-height: $_indicatorSize;
    border-radius: 0.375rem;
    padding: 0.75rem;
    position: absolute;
    left: var(--progress, 0%);
    border-color: #f8fafc; // Blend of white and (gray-0, 0.4) (alternate table row bg color)
    border-style: solid;
    border-width: 0 $_indicatorMargin 0 $_indicatorMargin;

    .indicatorLabel {
      margin-left: 0.625rem;
    }

    &.alignCenter {
      transform: translateX(-50%);
    }

    &.alignEnd {
      transform: translateX(-100%);
    }
  }
}

.meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.25rem;
  font-size: 0.875rem;
}
