@import "@/scss/variables";

.actionModal {
  line-height: 1.5;
  max-width: 31.125rem;
  width: 100%;

  &.confirm {
    text-align: center;
    padding: 2rem;
  }

  &.notes {
    max-width: 46.5rem;

    textarea {
      min-height: 13.5rem;
    }
  }

  .content {
    color: $gray-40;
    font-size: 1rem;
    margin-bottom: 2.5rem;
  }

  .paymentValue {
    display: flex;
    align-items: center;
    margin-bottom: 2.5rem;
    position: relative;

    .inputWrapperWrapper {
      position: static;
      flex: 1;
    }

    .input {
      text-align: center;
      font-size: 2.5rem;
      font-weight: 700;
    }

    .separator {
      background: rgba($gray-40, 0.5);
      border-radius: 50%;
      margin: 0.5rem;
      width: 0.5rem;
      height: 0.5rem;
      flex: 0 0 auto;
    }

    .currency {
      color: $gray-40;
      font-size: 0.875rem;
      pointer-events: none;
      transform: translateY(-50%);
      position: absolute;
      top: 50%;
      right: 0;
    }
  }

  .paymentForms {
    display: grid;
    grid-gap: 1.25rem;
    grid-template-columns: repeat(2, 1fr);

    .button:last-of-type {
      grid-column: span 2;
    }
  }

  .actions {
    display: grid;
    grid-gap: 2.5rem;
    grid-template-columns: repeat(auto-fit, minmax(5rem, 1fr));
  }
}

.notifyDriver {
  position: relative;
  overflow: hidden;
  min-width: 12.5rem;

  &[disabled] {
    pointer-events: none;
  }

  span {
    z-index: 1;
  }

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background: $success;
    transform: translateX(-100%);
  }

  &.loading::before {
    animation: notifyDriverLoading 1.5s linear;
  }

  &.ready {
    animation: notifyDriverReady 250ms;

    &::before {
      transform: translateX(0);
    }

    &,
    &:hover {
      color: $white;
    }
  }
}

@keyframes notifyDriverLoading {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes notifyDriverReady {
  from {
    transform: scale(0.9);
  }

  to {
    transform: scale(1);
  }
}
