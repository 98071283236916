@import "@/scss/variables";

.confirmModal {
  width: 100%;
  height: 100%;
  background: #f7fbff;
  z-index: $zindex-modal;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;

  .closeBtn {
    position: fixed;
    width: auto;
    top: 5.875rem;
    right: 3.75rem;

    .icon:hover {
      color: $primary;
    }
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    background: #fff;
    height: 100%;

    .button {
      border-radius: 0;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .section {
      padding: 3.75rem;
    }

    .infoSection {
      display: flex;
      flex-grow: 1;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid $gray-0;

      .refundEvent {
        flex-grow: 1;
        justify-content: space-between;
        font-size: 1.25rem;

        svg {
          width: 3rem;
          height: 3rem;
        }

        // #PiekłoPawłów
        & > div {
          position: relative;

          & :nth-child(1) > svg {
            margin-right: 1.5rem;
          }

          & :nth-child(2) {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);

            width: 1.5rem;
            height: 1.5rem;
          }

          & :nth-child(3) > svg {
            margin-left: 1.5rem;
          }
        }
      }

      .driver {
        display: flex;
        align-items: center;
        height: 100%;
      }
    }
  }

  .figure {
    display: none;
    align-self: end;
    justify-self: center;

    img {
      display: block;
      max-width: 100%;
    }
  }

  @media (min-width: 1000px) {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .figure {
      display: block;
    }
  }
}
