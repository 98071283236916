.provider {
  font-size: 1rem;
  max-width: 8rem;
  max-height: 3rem;

  &.small {
    max-width: 2.5rem;
  }

  & + .provider {
    margin-left: 1rem;
  }
}

.providersList {
  display: flex;
  align-items: center;
}
