@import "@deligoo/ui/src/scss/variables";

$defaultSize: 1rem !default;

$sizes: (
  "tiny": 0.6rem,
  "small": 0.875rem,
  "medium": 1.125rem,
  "large": 1.375rem,
  "extra-large": 1.75rem,
) !default;

.icon {
  width: $defaultSize;
  height: $defaultSize;
  color: currentColor;
  vertical-align: middle;
  display: inline-block;
}

@each $size, $value in $sizes {
  .#{$size} {
    width: $value;
    height: $value;
  }
}

@each $color, $value in $theme-colors {
  .#{$color} {
    color: $value;
  }
}

.gutterLeft {
  margin-left: 0.5rem;
}

.gutterRight {
  margin-right: 0.5rem;
}
