@import "@/scss/variables";

.state {
  color: #fff;
  font-size: 0.88rem;
  font-weight: 600;
  padding: 0.75rem 1.63rem;
  border-radius: 0.38rem;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;

  .icon {
    margin-left: 0.25rem;
  }

  &.finished {
    background: $success;
  }

  &.failed {
    background: $alert;
  }

  &.canceled,
  &.expired {
    background: $gray-40;
  }
}
