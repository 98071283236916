@import "@deligoo/ui/src/scss/variables";

.field {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  & > label {
    color: $gray-40;
    font-size: 0.75rem;
    font-weight: 600;
    margin-bottom: 0.75rem;
    display: block;
  }

  &.hasError label {
    color: $alert;
  }

  &.required label::after {
    content: "*";
    color: $alert;
    margin-left: 0.5rem;
  }
}

.inputWrapper {
  position: relative;
}

.help,
.error {
  color: $gray-40;
  font-size: 0.75rem;
  margin-top: 0.25rem;
  font-weight: normal;
  position: absolute;
  top: 100%;
}

.error {
  color: $alert;
  font-weight: bold;
}
