@import "@/scss/variables";

.tiles {
  display: grid;
  padding: 0 1.88rem 1.88rem;
  grid-gap: 1.88rem;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));

  @media (min-width: 1200px) {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(3, minmax(7.5rem, 1fr));
    grid-auto-flow: dense;

    &.dense {
      grid-gap: 0.625rem 1.875rem;
      padding: 0.625rem 1.875rem;
    }

    .nav {
      grid-column: span 2;
      grid-row: 1 / -1;
    }

    .wide {
      grid-column: span 2;
    }

    .tall {
      grid-row: span 2;
    }
  }
}

.nav {
  button {
    text-align: left;
    width: 100%;
  }

  .navCard {
    color: $gray-40;
    background: $gray-100;
    transition: background 250ms;

    .icon {
      margin-right: 1rem;
    }

    &:hover {
      background: $gray-80;
    }
  }

  .navButtons {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
    padding: 1.25rem;
  }

  .navButton {
    justify-content: flex-start;
  }

  .navDropdown {
    transform: translateY(-1rem);
    padding: 0;
    min-width: 30rem;
  }
}
