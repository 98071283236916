@import "@/scss/variables";

.summaryList {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.item {
  color: currentColor;
  display: flex;
  align-items: center;
  margin: 0.5rem 0;

  &:not(:last-child) {
    border-right: 1px solid $gray-05;
    padding-right: 1rem;
    margin-right: 1rem;
  }
}

.icon {
  margin-right: 1rem;
}

.title {
  color: #000;
  font-size: 1rem;
  margin: 0 1rem 0 0;
}

.desc {
  color: inherit;
  font-size: 1rem;
  margin: 0;
}
