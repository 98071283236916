@import "@deligoo/ui/src/scss/variables";
@import "@deligoo/ui/src/scss/mixins";

.dropdown {
  padding: 0;
  margin-top: 0.75rem;
  border-radius: 0.75rem;

  &.text {
    padding: 1.25rem;
  }
}

.menuItem {
  @include reset-button;

  color: $gray-60;
  font-size: 1rem;
  text-align: left;
  width: 100%;
  display: block;
  padding: 0 1.5625rem;
  transition: color 250ms;

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.25rem 0;
    width: 100%;
  }

  .arrow {
    color: $gray-40;
    margin-left: 1rem;
    transition: color 250ms, transform 250ms;
  }

  &:first-child {
    border-radius: 0.75rem 0.75rem 0 0;
  }

  &:last-child {
    border-radius: 0 0 0.75rem 0.75rem;
  }

  &:not(:last-child) .content {
    border-bottom: 1px solid $gray-05;
  }

  &:hover {
    color: $gray-100;
    background: rgba($gray-05, 0.35);
  }

  &:hover .arrow {
    color: $gray-100;
    transform: translateX(0.25rem);
  }
}
