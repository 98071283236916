@import "@/scss/variables";

// TODO: Fix vertical alignment
.tab {
  display: inline-block;

  .button {
    transition: none;
  }

  &.inactive {
    padding: 0 2rem;
    border-right: 1px solid $gray-05;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
      border-right: none;
    }
  }
}
