@import "@/scss/variables";

$_gutter: 1.875rem;

.wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  min-width: var(--minWidth, 1000px);
  overflow-x: auto;
}

.table {
  --columns: repeat(auto-fit, minmax(150px, auto));
  --gutter: #{$_gutter};

  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.th,
.td {
  &:first-of-type {
    padding-left: 0;
  }

  &:last-of-type {
    padding-right: 0;
  }
}

.th {
  color: $gray-40;
  font-size: 0.875rem;
  font-weight: 600;
  padding: $_gutter;
}

.tr {
  display: grid;
  align-items: center;
  grid-template-columns: var(--columns);

  &.indicator {
    border-left: 4px solid currentColor;
  }

  @each $color, $value in $theme-colors {
    &.indicator.#{$color} {
      color: $value;
      border-color: $value;
    }
  }
}

.td {
  color: $gray-60;
  font-size: 1rem;
  padding: $_gutter;

  &.indicatorLabel {
    color: currentColor;
  }
}

.thead {
  background: #fff;
  padding: 0 var(--gutter);
  position: sticky;
  z-index: 1;
  top: 0;

  .tr {
    border-bottom: 1px solid $gray-05;
  }
}

.tbody {
  color: $gray-60;
  flex-grow: 1;

  .tr {
    padding: 0 var(--gutter);
    position: relative;

    &::after {
      content: "";
      width: calc(100% - 2 * var(--gutter));
      height: 1px;
      background: $gray-05;
      position: absolute;
      left: var(--gutter);
      bottom: 0;
    }
  }

  .tr.action {
    transition: opacity 250ms;
    cursor: pointer;
  }

  .tr:nth-of-type(odd) {
    background: rgba($gray-0, 0.4);
  }
}

.status {
  color: $gray-40;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  height: 100%;

  .icon {
    width: 3rem;
    height: 3rem;
    display: block;
    margin: 0 auto 1rem;
  }

  .spinner {
    margin: auto;
  }
}
