@import "@/scss/variables";

.modal {
  width: 100%;
  max-width: 62.5rem;
}

.loading,
.results {
  min-height: 8rem;
  margin-top: 1.5rem;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form {
  display: grid;
  grid-gap: 2.5rem;
  grid-template-columns: 1fr;
  align-items: end;

  @media (min-width: $mobileBreakpoint) {
    grid-template-columns: 1fr 1fr 0.5fr 0.5fr;
  }
}

.results {
  display: grid;
  grid-gap: 1rem;
  align-items: end;

  @media (min-width: $mobileBreakpoint) {
    grid-gap: 3.75rem;
    grid-template-columns: 1fr auto auto;
  }

  .itemContent {
    min-height: 5rem;
    display: flex;
    align-items: center;
    flex: 1 1 100%;
  }
}
