@import "@/scss/variables";

.basic {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  padding: 2rem;

  @media (min-width: 900px) {
    padding: 6.25rem;
  }

  .header {
    @media (min-width: 400px) {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  .logotype {
    flex-shrink: 0;
    user-select: none;
    transition: opacity 250ms;

    &:hover {
      opacity: 0.75;
    }

    img {
      height: 1.75rem;
      object-fit: contain;

      @media (min-width: 600px) {
        height: 2.25rem;
      }
    }
  }

  .buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;

    @media (max-width: 400px) {
      margin-top: 2rem;
    }

    @media (min-width: 400px) {
      margin-left: 2rem;
    }

    @media (min-width: 700px) {
      margin-left: 5rem;
      gap: 5rem;
    }
  }

  .button {
    font-weight: bold;

    @media (min-width: 700px) {
      font-size: 1.5rem;

      &Icon {
        width: 1.88rem;
        height: 1.88rem;
        margin-right: 1rem;
      }
    }
  }

  .siteName {
    color: $primary;
    font-size: 1rem;
    font-weight: 600;
    flex: 1 1 100%;
    margin: 1.25rem 0.31rem;
  }
}

.main {
  flex-grow: 1;
  flex-direction: column;
  display: flex;
  overflow: hidden;
}
