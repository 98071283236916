@import "@/scss/variables";

.orders {
  background: $white;
}

.header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 2.125rem 1.875rem 0;
  overflow-x: auto;
}

//
// Table elements

.paymentRefund {
  color: $alert;
  display: flex;
  align-items: center;
  text-align: left;

  .paymentRefundIcon {
    flex-shrink: 0;
    margin-right: 1.25rem;
  }
}

.groupedOrdersRow {
  position: relative;

  &:nth-of-type(odd) {
    background: rgba($gray-0, 0.4);
  }
}

.orderRow {
  // Override Table styles
  background: initial !important;
  position: initial !important;
}

.provider {
  height: 1.25em;
  border-left: 1px solid $gray-05;
  padding-left: 1rem;
  margin-left: 1rem;

  img {
    height: 100%;
  }
}

.centered {
  // Center within multiple rows (for linked orders)
  transform: translateY(-50%);
  position: absolute;
  top: 50%;
}

.orderProgressWrapper {
  min-width: 20rem;
}

.driverWrapper {
  display: flex;
  flex-direction: row;
}
