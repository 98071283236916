@import "@deligoo/ui/src/scss/variables";
@import "@deligoo/ui/src/scss/mixins";

$_innerGutter: 2rem;

.modal {
  max-height: 100%;
  background: $white;
  transition: opacity $transition;
  border-radius: $border-radius-md;
  z-index: $zindex-modal;
  overflow: auto;

  &.loading {
    text-align: center;
  }

  @media (max-width: $mobileBreakpoint) {
    width: 100%;
    height: 100%;
    border-radius: 0;
    position: relative;
  }

  @media (min-width: $mobileBreakpoint) {
    margin: 2rem;
  }
}

.content {
  color: $gray-60;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  padding: $_innerGutter;
}

.title {
  color: $gray-80;
  font-weight: 700;
  font-size: 1.25rem;
  padding: $_innerGutter $_innerGutter 0;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: $_innerGutter $_innerGutter 0;

  .title {
    padding: 0;
    margin: 0;
  }
}

.footer {
  padding: $_innerGutter;
}

.spinner {
  margin: 2rem;
  display: inline-block;
}

.close {
  padding: $_innerGutter $_innerGutter 0;
  text-align: right;

  .closeBtn {
    color: $primary;
    border: none;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    background: rgba($primary, 0.15);

    &:hover {
      background: rgba($primary, 0.25);
    }
  }

  @media (min-width: $mobileBreakpoint) {
    &:not(.inside) {
      padding: 0;

      .closeBtn {
        position: absolute;
        right: 2rem;
        top: 2rem;
      }
    }
  }
}
