@import "@deligoo/ui/src/scss/variables";
@import "@deligoo/ui/src/scss/mixins";
@import "~tippy.js/dist/tippy.css";

.leaflet-tooltip.custom-leaflet-tooltip {
  @include tooltip-common;

  transition: opacity 250ms;
  padding: 0.5rem 0.75rem;
}

.tippy-box {
  @include tooltip-common;

  .tippy-content {
    padding: 0;
    overflow: hidden;
  }
}

.tippy-box[data-theme~="light"] {
  &[data-placement^="top"] > .tippy-arrow::before {
    border-top-color: $white;
  }

  &[data-placement^="bottom"] > .tippy-arrow::before {
    border-bottom-color: $white;
  }

  &[data-placement^="left"] > .tippy-arrow::before {
    border-left-color: $white;
  }

  &[data-placement^="right"] > .tippy-arrow::before {
    border-right-color: $white;
  }

  & > .tippy-backdrop {
    background-color: $white;
  }

  & > .tippy-svg-arrow {
    fill: $white;
  }
}

.tippy-box[data-theme~="dark"] {
  color: $gray-05;
  background-color: $gray-80;

  &[data-placement^="top"] > .tippy-arrow::before {
    border-top-color: $gray-80;
  }

  &[data-placement^="bottom"] > .tippy-arrow::before {
    border-bottom-color: $gray-80;
  }

  &[data-placement^="left"] > .tippy-arrow::before {
    border-left-color: $gray-80;
  }

  &[data-placement^="right"] > .tippy-arrow::before {
    border-right-color: $gray-80;
  }

  & > .tippy-backdrop {
    background-color: $gray-80;
  }

  & > .tippy-svg-arrow {
    fill: $gray-80;
  }
}

.tippy-box[data-theme~="alert"] {
  color: $white;
  background-color: $alert;

  &[data-placement^="top"] > .tippy-arrow::before {
    border-top-color: $alert;
  }

  &[data-placement^="bottom"] > .tippy-arrow::before {
    border-bottom-color: $alert;
  }

  &[data-placement^="left"] > .tippy-arrow::before {
    border-left-color: $alert;
  }

  &[data-placement^="right"] > .tippy-arrow::before {
    border-right-color: $alert;
  }

  & > .tippy-backdrop {
    background-color: $alert;
  }

  & > .tippy-svg-arrow {
    fill: $alert;
  }
}
