@import "@deligoo/ui/src/scss/variables";

$btnTransition: color $transition, background $transition;

.button {
  font: inherit;
  color: $gray-40;
  font-weight: 600;
  text-decoration: none;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  flex-wrap: wrap;

  box-sizing: border-box;
  border-radius: $border-radius-sm;
  border: 0;

  transition: $btnTransition;
  cursor: pointer;
}

//
// Size variations

.small {
  font-size: 0.75rem;
  padding: 0.15rem 0.75rem;
  height: map-get($controlHeights, "small");
}

.medium {
  font-size: 0.875rem;
  padding: 0.15rem 1.125rem;
  height: map-get($controlHeights, "medium");
}

.large {
  font-size: 1rem;
  padding: 0.15rem 2rem;
  height: map-get($controlHeights, "large");
}

.extra-large {
  font-size: 1rem;
  padding: 0.15rem 2rem;
  height: map-get($controlHeights, "extra-large");
}

//
// Icon button (only icon, without any other children)

.iconOnly {
  padding: 0.15rem;

  @each $size, $value in $controlHeights {
    &.#{$size}:not(.block, .text) {
      width: $value;
    }
  }
}

//
// Rounded / pill

.pill {
  border-radius: 2rem;
}

//
// Full-width

.fullWidth {
  width: 100%;
}

//
// Button variations

.solid {
  background: $gray-05;

  &:hover {
    background: $gray-0;
  }
}

.outlined {
  background: unset;
  border: 1px solid currentColor;
}

.contrast {
  background: unset;
}

.text {
  padding: 0;
  height: auto;
  background: unset;

  &:hover {
    background: unset;

    .label {
      text-decoration: underline;
    }
  }
}

@each $color, $value in $theme-colors {
  .#{$color} {
    &.solid {
      color: $white;
      background: $value;

      &:active,
      &:hover {
        background: lighten($value, 10%);
      }

      &.raised {
        box-shadow: 0 0.75rem 2rem rgba($value, 0.3);
        transition: $btnTransition, box-shadow $transition;

        &:active,
        &:hover {
          box-shadow: 0 0 1rem rgba($value, 0.3);
        }
      }

      .spinner {
        border-color: rgba($white, 0.15);
        border-left-color: currentColor;
      }

      @if ($color == "light") {
        color: $gray-80;

        .spinner {
          border-color: rgba($gray-80, 0.15);
          border-left-color: currentColor;
        }
      }
    }

    &.text {
      color: $value;
    }

    &.outlined,
    &.contrast,
    &.accented {
      color: $value;

      &:active,
      &:hover {
        background: map-get($theme-colors-alt, $color);
      }

      .spinner {
        border-color: rgba($value, 0.15);
        border-left-color: $value;
      }
    }

    &.outlined {
      @if ($color == "muted") {
        border-color: $gray-05;
      }
    }

    &.contrast,
    &.accented {
      background: $white;

      @if ($color == "light") {
        color: $gray-100;

        &:active,
        &:hover {
          background: $gray-05;
        }

        .spinner {
          border-color: rgba($gray-100, 0.15);
          border-left-color: currentColor;
        }
      }

      @if ($color == "dark") {
        color: $white;
        background: $gray-100;

        &:active,
        &:hover {
          background: $gray-60;
        }
      }

      .spinner {
        border-color: rgba($white, 0.15);
        border-left-color: currentColor;
      }
    }

    &.accented {
      background: rgba(map-get($theme-colors-alt, $color), 0.45);

      @if ($color == "light") {
        color: $gray-100;
        background: rgba(map-get($theme-colors-alt, $color), 0.15);

        &:active,
        &:hover {
          background: $gray-05;
        }
      }

      @if ($color == "dark") {
        color: $white;
        background: rgba(map-get($theme-colors, $color), 0.35);

        &:active,
        &:hover {
          background: $gray-60;
        }
      }
    }
  }
}

//
// Disabled variation

.disabled {
  cursor: default;

  &.solid,
  &.solid:hover {
    color: $gray-40;
    background: $gray-05;
  }

  &.outlined,
  &.outlined:hover {
    color: $gray-40;
    border-color: $gray-05;
    background: unset;
  }

  &.text,
  &.text:hover {
    color: $gray-40;

    .label {
      text-decoration: none;
    }
  }

  &.contrast,
  &.contrast:hover {
    color: $gray-40;
    background: $white;
  }

  &.contrast.dark,
  &.contrast.dark:hover {
    color: rgba($white, 0.4);
    background: $gray-100;
  }

  &.accented,
  &.accented:hover {
    color: $gray-40;
    background: $gray-0;
  }

  // Disable event propagation
  span {
    pointer-events: none;
  }
}

//
// Button with icon

$iconGutter: (
  "small": 0.625rem,
  "medium": 0.75rem,
  "large": 0.875rem,
);

@each $gutter, $value in $iconGutter {
  .left .label {
    margin-left: $value;
  }

  .right .label {
    margin-right: $value;
    order: -1;
  }
}

//
// Loading

.loading {
  pointer-events: none;
  position: relative;

  .label,
  .icon {
    opacity: 0;
  }

  .spinner {
    margin: auto;
    pointer-events: none;
    position: absolute;
    left: 0;
    right: 0;
  }
}
