@import "@/scss/variables";

.driver {
  display: flex;
  align-items: center;
  height: 100%;
  color: $gray-60;

  .info {
    flex-grow: 1;
    margin-left: 1.125rem;
  }
}
