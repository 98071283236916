@import "@/scss/variables";

@mixin gradient {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $zindex-modal + 1;
  pointer-events: none;
}

@keyframes slideFromRight {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

.notifications {
  display: flex;
  flex-direction: column;
  background: $white;
  width: 50%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 50%;
  overflow: hidden;
  padding: 2.5rem;
  box-shadow: 0 0.25rem 9.375rem rgba(31, 39, 58, 0.2);

  @media (max-width: $mobileBreakpoint) {
    left: 0;
    width: 100%;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 2.5rem;
    z-index: $zindex-modal + 2;
  }

  .list {
    flex-grow: 1;
    overflow-y: auto;
    position: relative; // for gradient

    .item {
      &:first-child {
        padding-top: 0;
      }

      &:not(:last-child) {
        border-bottom: 1px solid $gray-05;
      }
    }
  }

  .clearAllBtn {
    flex-shrink: 0;
    z-index: $zindex-modal + 2;
  }

  &.gradientBottom::before {
    @include gradient;
    background: linear-gradient(
      180deg,
      transparent 0%,
      transparent 66%,
      #fff 100%
    );
  }

  &.gradientTop::before {
    @include gradient;
    background: linear-gradient(
      180deg,
      #fff 0%,
      transparent 33%,
      transparent 100%
    );
  }

  &.gradientBoth::before {
    @include gradient;
    background: linear-gradient(
      180deg,
      #fff 0%,
      transparent 33%,
      transparent 66%,
      #fff 100%
    );
  }
}

.notification {
  display: flex;
  padding: 2.5rem 0;

  .icon {
    margin: 1.25rem;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 0.5rem;

    .time {
      font-size: 0.875rem;
      font-weight: 500;
      color: $gray-40;
      margin-bottom: 1.25rem;
    }

    .message {
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.5rem;
      color: $gray-60;
    }
  }
}

.backdrop {
  animation: slideFromRight 250ms;
  animation-timing-function: linear;
}
