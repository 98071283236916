@import "@deligoo/ui/src/scss/variables";

.backdrop {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba($gray-100, 0.65);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: $zindex-modal-backdrop;
  animation: fadeIn 250ms;
  cursor: pointer;

  &.transparent {
    background: transparent;
  }

  &.light {
    background: rgba($gray-0, 0.95);
  }

  & > div {
    cursor: initial;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
