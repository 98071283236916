@import "@deligoo/ui/src/scss/variables";

$switchTransition: 150ms ease-in-out;
$width: 4em;
$height: 2em;

.label {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  transition: opacity $switchTransition;
  cursor: pointer;

  &:hover {
    opacity: 0.85;
  }

  .labelText {
    margin-right: 1.5em;
  }

  .switch {
    width: $width;
    min-width: $width;
    height: $height;
    display: flex;
    align-items: center;
    border-radius: 6.25em;
    background: $alert-05;
    position: relative;
    cursor: pointer;
    transition: background $switchTransition;

    &::before {
      content: "";
      width: 1.25em;
      height: 1.25em;
      margin: 0.375em;
      background: $alert;
      border-radius: 50%;
      transition: transform $switchTransition;
    }

    @each $color, $value in $theme-colors {
      &.#{$color}::before {
        background: $value !important;
      }
    }
  }

  input {
    display: none;
  }

  input:checked + .switch {
    background: $success-05;

    &::before {
      transform: translateX($width - 2em);
      background: $success;
    }
  }

  input:disabled + .switch {
    background: $gray-0;
    pointer-events: none;

    &::before {
      background: $gray-40;
    }
  }

  @each $color, $value in $theme-colors-alt {
    & .bg-#{$color} {
      background: $value !important;
    }
  }

  & .bg-white {
    background: $white !important;
  }
}

.small {
  font-size: 0.6875rem;
}

.medium {
  font-size: 1rem;
}

.large {
  font-size: 1.125rem;
}
