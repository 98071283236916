@import "@/scss/variables";

$_breakpoint: 962px;

.topbar {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  background: $gray-0;
  padding: 1.875rem;

  @media (min-width: $_breakpoint) {
    display: flex;
    align-items: center;
  }
}

.actionBtn {
  min-width: 13.75rem;
  transition: none;
  width: 100%;

  @media (min-width: $_breakpoint) {
    margin-right: 0.5rem;
    width: auto;
  }
}

.restaurantBtn {
  flex-shrink: 0;

  .content {
    display: flex;
    align-items: center;
    margin-right: -0.5rem;
  }

  .label {
    max-width: 10rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;

    @media (min-width: $_breakpoint) {
      max-width: 5rem;
    }

    @media (min-width: $_breakpoint + 300px) {
      max-width: 15rem;
    }
  }
}

.nav {
  display: flex;
  grid-column: span 2;
  max-height: 3rem;
  order: -1;

  .navItem {
    line-height: 1;
    display: flex;
    align-items: center;

    & + .navItem::before {
      content: "";
      width: 1px;
      height: 3rem;
      margin: 0 1.875rem;
      background: rgba($gray-40, 0.2);
    }
  }

  .iconButtons {
    display: flex;
    align-items: center;
    margin-left: auto;

    .navItem {
      & + .navItem::before {
        margin: 1.25rem;
      }
    }

    .navBtn {
      transition: opacity 250ms;

      &:hover {
        opacity: 0.65;
      }
    }
  }

  @media (min-width: $_breakpoint) {
    order: unset;
    margin-left: auto;
    justify-content: flex-end;

    .iconButtons {
      margin: 0 0 0 2.75rem;
    }
  }

  @media (min-width: $_breakpoint + 200px) {
    .iconButtons {
      margin: 0 1.875rem 0 3.75rem;
    }
  }
}

.tooltipBadge {
  font-weight: 700;
  font-size: 0.625rem;
  padding: 0.375rem;
}

.notificationAlert {
  animation: shake 2s infinite;
}

.updatingIndicator {
  flex-shrink: 0;

  @media (max-width: $_breakpoint) {
    transform: translateY(-50%);
    position: absolute;
    left: 50%;
  }
}

@keyframes shake {
  0%,
  10%,
  20%,
  30%,
  40% {
    transform: rotate(-10deg);
  }

  5%,
  15%,
  25%,
  35%,
  45% {
    transform: rotate(10deg);
  }

  50%,
  100% {
    transform: rotate(0);
  }
}
