@import "@/scss/variables";

.status {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background: $warning-50;
  display: inline-block;
  flex-shrink: 0;

  &.online {
    background: $success;
  }

  &.locked {
    background: $alert;
  }
}
