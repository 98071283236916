@import "@/scss/variables";
@import "~react-toastify/dist/ReactToastify.css";

.Toastify {
  &__toast {
    font-family: inherit;
    font-size: 1rem;
    font-weight: 600;
    padding: 1.75rem 1.875rem;
    border-radius: $border-radius-md;
    width: 80vw;
    max-width: 37.5rem;

    &-container {
      width: auto;
    }

    &-body {
      padding: 0;
      width: 100%;
    }
  }

  &__close-button {
    line-height: 1;
    align-self: center;
    margin-left: 1rem;
  }
}
