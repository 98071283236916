@import "@/scss/variables";

.content {
  display: flex;
  flex-direction: column;
}

.desc {
  margin-top: 2.5rem;
}

.templates {
  display: flex;
  flex-wrap: wrap;
  padding: 2.5rem 0;
  margin: -0.94rem;

  .template {
    flex: 1 1 auto;
    margin: 0.94rem;
  }
}

.notes {
  flex: 1 1 100%;
}
