@import "@/scss/variables";

.messages {
  display: grid;
  grid-template:
    "list header" calc(6.625rem + 1px)
    "list messageContent" 1fr / 1fr 2fr;
  width: 100%;
  height: 100%;
  background: $white;
  top: 0;
  left: 0;
  margin: 0;
  border-radius: 0;

  @media (max-width: 800px) {
    grid-template:
      "header" calc(6.625rem + 1px)
      "list";

    .messageContent {
      display: none !important;
    }
  }

  .list {
    @include hide-scrollbar;
    grid-area: list;
    background: $gray-100;
    overflow: auto;

    .messagesList {
      list-style-type: none;
      margin: 0;
      padding: 0;

      .messageItem {
        color: $white;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 1.875rem;
        height: 6.625rem;
        background: $gray-100;
        transition: background 250ms;
        cursor: pointer;

        &:hover {
          background: $gray-80;
        }

        .datetime {
          color: $gray-60;
          font-size: 0.875rem;
        }

        .messageTitle {
          color: $gray-40;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          font-size: 1rem;
        }

        &.unread {
          .datetime {
            color: $gray-40;
          }

          .messageTitle {
            color: $white;
          }
        }

        &.active {
          background: $white;

          .messageTitle {
            color: $black;
          }
        }

        &.autoHeight {
          height: auto;

          .messageTitle {
            margin-top: 0.5rem;
          }

          .expandedMessage {
            margin-top: 0.5rem;
            color: $black;
            font-size: 1rem;
            font-weight: 500;
          }
        }
      }
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-area: header;
    padding: 2rem 1.875rem;
    border-bottom: 1px solid $gray-05;

    .readAllBtn {
      margin: 0 1.875rem;
      flex-grow: 1;
    }
  }

  .messageContent {
    grid-area: messageContent;
    padding: 1.875rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .datetime {
      font-size: 0.875rem;
      font-weight: 600;
      margin-bottom: 1rem;
    }

    .title {
      font-size: 1.25rem;
      font-weight: 600;
      margin-bottom: 2rem;
    }

    .message {
      font-size: 1rem;
      font-weight: 500;
    }
  }
}
