@import "@deligoo/ui/src/scss/variables";

$radioColors: (
  "primary": $primary,
  "success": $success,
  "alert": $alert,
);

.wrapper {
  display: flex;
}

.label {
  display: flex;
  cursor: pointer;
}

.box {
  width: 1rem;
  height: 1rem;
  margin-right: 0.75rem;
  border-radius: 50%;
  background-color: $white;
  border: 0.125rem solid $gray-05;
  transition: background-color $transition, border-color $transition;

  display: flex;
  align-items: center;
  justify-content: center;

  .dot {
    color: $white;
    flex-shrink: 0;
    width: 0.375rem;
    height: 0.375rem;
    border-radius: 50%;
    transition: background-color $transition, border-color $transition;
  }
}

.input {
  display: none;

  &:checked + .label .box {
    color: $white;
    background-color: $white;
    border: 0.125rem solid;
  }

  &:disabled {
    color: $gray-20;

    & + .label {
      cursor: default;
      color: $gray-20;

      .box {
        background-color: $gray-0;
        border: 0.125rem solid $gray-05;
      }
    }
  }
}

@each $color, $value in $radioColors {
  .radioInput.#{$color} .input:checked + .label .box {
    border-color: $value;

    .dot {
      background: $value;
    }
  }
}

.radioInput + .radioInput {
  margin-left: 2rem;
}

.radioButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  flex-basis: 0;
  border: 1px solid $gray-05;
  padding: 2rem;
  border-radius: $border-radius-sm;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 1.5rem;
  }

  &.checked {
    border-color: $primary;
  }

  @each $color, $value in $radioColors {
    // TODO
    &.checked-#{$color} {
      border-color: $value;
    }
  }
}

.radioButtonGroup {
  display: flex;
  justify-content: space-between;
}
