@import "@deligoo/ui/src/scss/variables";

$_inputPadding: 1rem;
$_iconSize: 1.25rem;

.selectWrapper {
  position: relative;

  .iconLeft,
  .iconRight {
    padding: 0.65rem;
    width: 2.5rem;
    height: 100%;
    position: absolute;
    pointer-events: none;
    text-align: center;
  }

  .iconLeft {
    left: 0;
  }

  .iconRight {
    right: 0;
  }
}

.select {
  color: $gray-60;
  font-size: inherit;
  font-family: inherit;
  transition: border-color $transition;
  padding: 0 $_inputPadding;
  font-weight: 600;
  border-radius: $border-radius-sm;
  background-color: $white;
  border: 2px solid $gray-05;
  cursor: pointer;
  appearance: none;
  width: 100%;

  &:focus-visible {
    outline: none;
  }

  &:focus {
    border-color: $primary;
  }

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.hasIconLeft {
    padding-left: 2.25rem;
  }

  &.hasIconRight {
    padding-right: 2.25rem;
  }

  &.dark {
    color: $white;
    background: $gray-80;
    border-color: $gray-80;

    &:hover,
    &:focus {
      border-color: $gray-60;
    }
  }
}

//
// Size variations

.small {
  .select {
    font-size: 0.75rem;
    height: map-get($controlHeights, "small");
  }

  .iconLeft,
  .iconRight {
    padding: map-get($controlHeights, "small") * 0.25;
    width: map-get($controlHeights, "small");
  }

  .hasIconLeft {
    padding-left: map-get($controlHeights, "small");
  }

  .hasIconRight {
    padding-right: map-get($controlHeights, "small");
  }
}

.medium .select {
  font-size: 0.875rem;
  height: map-get($controlHeights, "medium");
}

.large .select {
  font-size: 1rem;
  height: map-get($controlHeights, "large");
}

.extra-large .select {
  font-size: 1rem;
  height: map-get($controlHeights, "extra-large");
}
