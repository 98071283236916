@import "colors";

//
// RWD & breakpoints

$mobileBreakpoint: 800px;
$desktopBreakpoint: 1100px;

//
// Component elements

$shadow-1: 0 2px 5px rgba(#435664, 0.12);
$shadow-2: 0 5px 8px rgba(#435664, 0.12);
$shadow-3: 0 8px 16px rgba(#435664, 0.08);
$shadow-4: 0 16px 32px rgba(#435664, 0.17);
$shadow-5: 0 61px 84px rgba(#435664, 0.14);

$border-radius-sm: 0.375rem;
$border-radius-md: 0.75rem;
$border-radius-lg: 1rem;

$transition: 250ms ease-in-out;

//
// Layers

$zindex-dropdown: 1000;
$zindex-sticky: 1020;
$zindex-fixed: 1030;
$zindex-modal-backdrop: 1040;
$zindex-modal: 1050;
$zindex-popover: 1060;
$zindex-tooltip: 1070;

//
// Control heights (buttons, inputs, badges etc.)

$controlHeights: (
  "small": 2rem,
  "medium": 2.75rem,
  "large": 3rem,
  "extra-large": 3.75rem,
);
