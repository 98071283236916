@import "@/scss/variables";

$_breakpoint: 700px;

.toast {
  text-align: center;
  width: calc(100vw - 2rem);
  max-width: 100%;
  margin: 1rem;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  font-size: 1.15rem;
  font-weight: 600;
  margin-right: auto;
  padding: 2rem 0;
}

.actions {
  display: flex;
  justify-content: center;
}

.button {
  height: auto;
  font-weight: 700;
  text-transform: uppercase;
  background: $white;
  border-radius: 6px;
  min-width: 9rem;
  min-height: 2.875rem;
  padding: 0.6875rem 1.125rem;
  transition: background 250ms;

  &:hover {
    background: rgba($white, 0.85);
  }

  & + .button {
    margin-left: 1rem;
  }

  &.leaveForLater {
    background: rgba($white, 0.15);
    color: $white;

    &:hover {
      background: rgba($white, 0.1015);
    }
  }
}

@media (min-width: $_breakpoint) {
  .toast {
    text-align: unset;
    max-width: 70.125rem;
    width: 90vw;
    padding: 0;
  }

  .body {
    display: grid;
    grid-template-columns: 10rem 1fr;
  }

  .text {
    font-size: 1rem;
    padding: 0;
    flex: 1 1 100%;
  }

  .logo {
    padding: 1rem 1.5rem;
    border-right: 1px solid rgba($white, 0.1);
  }

  .content {
    display: flex;
    align-items: center;
    padding: 1rem 1.5rem;
  }

  .button {
    margin-left: 1rem;
  }
}

@media (min-width: $_breakpoint + 100px) {
  .text {
    flex: unset;
  }

  .button {
    font-size: 0.75rem;
  }
}

//
// Color variations

@each $color, $value in $provider-colors {
  .bg-#{$color} {
    color: $gray-0;
    background: $value;
  }
}

@each $color, $value in $provider-colors {
  .#{$color} {
    color: $value;
  }
}
