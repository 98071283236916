.paymentForm {
  display: inline-flex;
  align-items: baseline;

  .icon {
    align-self: end;
  }

  .left {
    margin-right: 0.5rem;
  }

  .right {
    margin-left: 0.5rem;
  }
}
