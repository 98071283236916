@import "@/scss/variables";

.indicator {
  display: inline-block;
  position: relative;

  .count {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    color: $white;
    border-radius: 50%;
    background-color: $primary;
    width: 1.25rem;
    height: 1.25rem;
    top: -0.5rem;
    left: 55%;
  }

  @each $color, $value in $theme-colors {
    &.#{$color} .count {
      background-color: $value;
    }
  }
}
