@import "@/scss/variables";

//
// Leaflet styles

.leaflet-container {
  font-size: 1rem;
  font-family: inherit;
  width: 100%;
  height: 100%;
}

.leaflet-control-attribution {
  display: none;
}

//
// Route polylines

.route {
  stroke-width: 6;
}

//
// Marker common styles

@keyframes pulse {
  from {
    box-shadow: 0 0 0 0.15rem rgba($gray-40, 0.75);
  }

  to {
    box-shadow: 0 0 0 1rem rgba($gray-40, 0);
  }
}

@mixin marker-arrow {
  content: "";
  width: 0.15rem;
  height: 0.15rem;
  background: inherit;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 50%) rotate(45deg);
}

@mixin marker {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: $info;
}

//
// Driver marker

.DriverMarker {
  @include marker;
  background-color: #fff;
  background-size: 0.65rem;
  background-repeat: no-repeat;
  background-position: center;

  @each $color, $value in $theme-colors {
    $_fill: str-slice(#{$value}, 2, -1);

    &.#{$color} {
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='20' viewBox='0 0 18 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.8405 17.415L10.7606 1.14865C10.4543 0.444639 9.76542 0 9 0C8.23459 0 7.54572 0.444639 7.23943 1.14865L0.159482 17.415C-0.185082 18.193 0.0445418 19.0823 0.695271 19.601C1.34574 20.1198 2.30251 20.1198 2.99138 19.6753L8.99988 15.5993L15.0084 19.6753C15.6972 20.1199 16.654 20.1199 17.3045 19.601C17.9555 19.0823 18.1851 18.193 17.8405 17.415Z' fill='%23#{$_fill}'/%3E%3C/svg%3E%0A");
    }

    &.#{$color}.focused {
      background-color: $value;
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='20' viewBox='0 0 18 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.8405 17.415L10.7606 1.14865C10.4543 0.444639 9.76542 0 9 0C8.23459 0 7.54572 0.444639 7.23943 1.14865L0.159482 17.415C-0.185082 18.193 0.0445418 19.0823 0.695271 19.601C1.34574 20.1198 2.30251 20.1198 2.99138 19.6753L8.99988 15.5993L15.0084 19.6753C15.6972 20.1199 16.654 20.1199 17.3045 19.601C17.9555 19.0823 18.1851 18.193 17.8405 17.415Z' fill='%23FFFFFF'/%3E%3C/svg%3E%0A");
      animation: pulse 1s infinite;
      z-index: 500 !important;
    }
  }
}

//
// Restaurant marker
.RestaurantMarker {
  $_icon: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.8175 14.9359L8.90291 7.96563L9.89275 6.97619C10.7693 7.50463 11.905 7.5726 12.4282 7.5726C12.5614 7.5726 12.6551 7.56822 12.6943 7.56594C12.8732 7.55563 13.039 7.46903 13.1497 7.3281L15.8544 3.88235C16.0674 3.6111 16.0201 3.21856 15.7488 3.00563C15.4775 2.79266 15.085 2.83997 14.8721 3.11125L12.3513 6.3226C11.9509 6.31428 11.3256 6.25203 10.8219 6.04738L14.3998 2.47103C14.6437 2.22725 14.6438 1.83188 14.3999 1.58797C14.1561 1.34403 13.7608 1.34397 13.5168 1.58778L9.93722 5.16591C9.73216 4.66563 9.66994 4.04522 9.66203 3.63716L12.9342 1.11931C13.2075 0.909002 13.2585 0.516971 13.0483 0.243658C12.838 -0.0296542 12.4459 -0.0807167 12.1726 0.129565L8.66134 2.83141C8.51769 2.94194 8.42916 3.10944 8.41875 3.29038C8.40759 3.48388 8.34491 4.99369 9.009 6.09372L8.02334 7.07897L1.18475 0.18544C1.06781 0.0675958 0.908749 0.00112707 0.742749 0.000783321C0.742311 0.000783321 0.741905 0.000783321 0.741467 0.000783321C0.575905 0.000783321 0.417092 0.0665333 0.299967 0.183627L0.206999 0.276565C0.103686 0.379877 0.0398736 0.516127 0.0266236 0.661627C0.00943609 0.850908 0.00134234 1.04638 0.00256109 1.24241C0.0153111 3.31581 1.04525 5.74553 3.06378 8.46406C4.53919 10.4511 5.99084 11.8356 6.05194 11.8936C6.18556 12.0204 6.36856 12.0817 6.55156 12.0612L7.78375 11.9226C8.12647 11.884 8.37303 11.575 8.3345 11.2323C8.29594 10.8896 7.98669 10.6429 7.64419 10.6816L6.70803 10.7868C5.77131 9.84569 1.90678 5.7731 1.32481 2.09988L14.9309 15.8153C15.0529 15.9384 15.2135 16 15.3742 16C15.5331 16 15.6922 15.9397 15.8139 15.8189C16.0588 15.576 16.0604 15.1807 15.8175 14.9359Z' fill='white'/%3E%3C/svg%3E%0A");

  @include marker;
  background: $info $_icon no-repeat center;
  background-size: 1rem;

  // Arrow
  &::after {
    @include marker-arrow;
    background: $info;
  }
}
