@import "@deligoo/ui/src/scss/variables";
@import "@deligoo/ui/src/scss/mixins";
@import "@deligoo/ui/src/scss/colors";

//
// Providers colors

$provider-colors: (
  "deligoo": $primary,
  "deli1": $primary,
  "shop": $primary,
  "api": $primary,
  "marketplace": #1f273a,
  "jedzcochcesz": #1f273a,
  "glodny": #dc3c33,
  "pyszne": #f08633,
  "ubereats": #142328,
  "glovo": #ffc244,
  "makro": #002d72,
  "upmenu": #8d519b,
  "fastcat": #ee796b,
  "zioapp": #fbac19,
  "furgonetka": #2393c9,
  "restaumatic": #090942,
  "menupl": #3f37fb,
);
