@import "@/scss/variables";

.createOrder {
  color: $white;
  width: 100%;
  height: 100%;
  background: $gray-100;
  padding: 0 1.25rem 1.25rem;
  display: flex;
  flex-direction: column;
}

.header {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2.625rem;
  overflow: auto;

  .title {
    font-size: 1.5rem;
    font-weight: 700;
    padding: 2.625rem 0;
    margin: 0;
  }
}

.steps {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0 2rem;
  flex-shrink: 0;

  .step {
    display: flex;

    .link {
      display: flex;
      align-items: center;
      padding: 1.875rem;
      color: inherit;
      flex: 1 1 100%;
      transition: background 250ms;
      border-radius: $border-radius-sm;
      margin: 0 0.15rem;

      &:hover:not([disabled]) {
        background: rgba($white, 0.0875);
      }

      &[disabled] {
        cursor: initial;
      }
    }

    .icon {
      width: 1.5rem;
      height: 1.5rem;
    }

    .label {
      font-size: 1.25rem;
      margin-left: 1.25rem;
    }

    &.completed,
    &.pending {
      .label {
        display: none;
      }
    }

    &.completed {
      color: $success;
    }

    &.pending {
      color: $primary;
    }

    &:not(:last-child) {
      border-right: 1px solid rgba($white, 0.08);
    }
  }
}

.content {
  color: $gray-40;
  font-size: 1rem;
  background: $white;
  border-radius: 1.25rem;
  flex: 1 1 100%;
  overflow: auto;
}

.step {
  display: flex;
  flex-direction: column;
  height: 100%;

  .main {
    position: relative;
    padding: 2.5rem;
    flex: 1 1 100%;
  }

  .actions {
    flex-shrink: 0;
    display: grid;
    grid-gap: 1rem 2.5rem;
    grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
    padding: 0 2.5rem 2.5rem;
  }
}

.spinner {
  margin: auto;
}
