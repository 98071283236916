/* stylelint-disable selector-max-type -- This is not a module */
@import "variables";
@import "mixins";

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.25;
  font-family: "Montserrat", "Roboto", "Helvetica", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@for $i from 1 through 6 {
  h#{$i} {
    margin: 0;
  }
}

p {
  margin: 0;

  &:not(:last-of-type) {
    margin-bottom: 1.25rem;
  }
}

dl,
dd {
  margin: 0;
}

img {
  max-width: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  @include reset-button;
}

button:focus,
a:focus {
  outline: none;
}

label[for] {
  cursor: pointer;
}

input {
  border: 0;
  padding: 0.5rem;
}

textarea {
  color: $gray-80;
  font: inherit;
  font-weight: 600;
  border: 2px solid $gray-05;
  border-radius: $border-radius-sm;
  transition: border-color 250ms;
  padding: 1rem;
  max-width: 100%;
  min-width: 100%;
  min-height: 5rem;
  height: 100%;
  resize: vertical;

  &:focus {
    border-color: $primary;
    outline: 0;
  }

  &::placeholder {
    color: $gray-40;
  }
}
