@import "@/scss/variables";

.tiles {
  flex-grow: 1;
  display: grid;
  padding: 0.625rem;
  grid-gap: 0.625rem;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));

  @media (min-width: $mobileBreakpoint) {
    padding: 0 1.875rem 1.875rem;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-auto-flow: dense;
    grid-gap: 1.875rem;
    max-height: 40rem;

    &.dense {
      grid-gap: 0.625rem 1.875rem;
      padding: 0.625rem 1.875rem;
    }

    .wide {
      grid-column: span 2;
    }

    .tall {
      grid-row: span 2;
    }

    a,
    button {
      height: 100%;
    }
  }
}

.orders,
.payments {
  overflow: hidden;
  position: relative;
}

.orders {
  min-height: 16rem;

  .providers {
    margin-bottom: 2.125rem;

    @media (min-width: $mobileBreakpoint) and (max-width: $mobileBreakpoint + 50px) {
      margin-bottom: 1rem;
    }
  }

  .pizza,
  .box {
    position: absolute;
    bottom: -64px;
  }

  .pizza {
    left: -32px;
  }

  .box {
    right: -32px;
  }

  @media (min-width: $mobileBreakpoint) and (max-width: $mobileBreakpoint + 70px) {
    .pizza,
    .box {
      max-width: 10rem;
      bottom: -50px;
    }
  }

  @media (min-width: 1200px) {
    .pizza,
    .box {
      bottom: -2rem;
    }

    .pizza {
      left: 0;
    }

    .box {
      right: 0;
    }
  }
}

.payments {
  display: flex;
  justify-content: center;

  .card {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  @media (max-width: 1180px) {
    .card {
      right: -3rem;
    }
  }

  @media (max-width: 1700px) {
    .title {
      max-width: 10rem;
    }
  }
}

.summaryItem {
  flex-direction: row;
  padding: 1rem 1.5rem;

  .icon {
    margin-right: 1rem;
  }

  span {
    color: $gray-80;
    font-size: 1.25rem;
    font-weight: 700;
    padding-left: 1rem;
    margin-left: auto;
  }
}

.chart {
  font-size: 1.15rem;
}

.activity span {
  color: $gray-05;

  &:first-of-type {
    color: inherit;
  }

  &:last-of-type {
    color: $gray-40;
  }
}

.workingHoursStatus,
.pricingPolicyStatus {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pulsating {
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.97);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.97);
  }
}
