@import "@/scss/variables";

.nice-dates {
  $daySize: 2.75rem;

  &-navigation {
    color: $primary;
    margin-bottom: 2.375rem;

    &_previous::before,
    &_next::before {
      border-color: $primary;
    }

    &_previous.-disabled::before,
    &_next.-disabled::before {
      border-color: $gray-40;
      opacity: 0.5;
    }

    &_current {
      color: $gray-100;
      font-weight: 700;
      font-size: 1.25rem;
    }
  }

  &-week-header {
    box-shadow: none;
    margin-bottom: 0.75rem;

    &_day {
      color: $gray-40;
      opacity: 0.75;
      font-size: 0.75rem;
    }
  }

  &-day {
    color: $gray-60;
    font-size: inherit;
    transition: color 250ms, opacity 250ms;

    &::before,
    &::after {
      border-radius: $border-radius-sm;
      border: 3px solid #fff;
    }

    &::before {
      background-color: $primary;
    }

    &::after {
      color: $primary;
      background-color: rgba($primary, 0.1);
      opacity: 0;
    }

    &_date {
      color: inherit;
    }

    &_month {
      display: none;
    }

    &.-outside,
    &.-disabled:not(.-selected) {
      color: inherit;
      opacity: 0.4;
    }

    &.-selected {
      cursor: pointer;
      pointer-events: all;
      color: inherit;
      opacity: 1;
    }

    &:hover {
      color: $primary;
      opacity: 1;
    }
  }
}
