@import "@/scss/variables";

@keyframes pulse {
  from {
    opacity: 0.5;
  }

  to {
    opacity: 1;
  }
}

.autocomplete {
  position: relative;
}

.suggestions {
  width: 100%;
  margin: 0.5rem 0;
  background: #fff;
  box-shadow: 0 0.5rem 3rem rgba(26, 26, 26, 0.16);
  border-radius: 0.38rem;
  padding: 0.875rem;
  max-height: 50vh;
  overflow: auto;
  position: absolute;
  top: 100%;
  z-index: 1;

  .list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .suggestion {
    color: $gray-40;
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 500;
    text-align: left;
    padding: 0.5rem;
    width: 100%;
    display: block;
    border-radius: $border-radius-sm;
    transition: background 250ms, color 250ms;

    &.selected {
      color: $primary;
      background: rgba($gray-05, 0.35);

      span {
        color: inherit;
      }
    }

    span {
      color: $gray-60;
      font-weight: bold;
      transition: color 250ms;
    }

    div + div {
      font-size: 0.75rem;
    }
  }
}

.loading {
  animation: pulse 1s alternate infinite;
  margin-top: 1rem;
}
