@import "@/scss/variables";

$_gutter: 1.375rem;

@keyframes pulse {
  from {
    opacity: 0.5;
  }

  to {
    opacity: 1;
  }
}

.card {
  color: $gray-40;
  display: flex;
  font-size: 0.75rem;
  flex-direction: column;
  background: #fff;
  border-radius: 0.75rem;
  position: relative;
  padding: 1.25rem;
  height: 100%;

  &.inline {
    display: grid;
    grid-gap: 0 1rem;
    grid-template-columns: auto 1fr;
    grid-auto-flow: column;

    .footer {
      padding: 0;
      margin: 0;
      margin-left: auto;
      align-self: center;
      grid-row: 1 / 100;
      padding-left: $_gutter;
    }

    .actionBtn {
      margin: auto;
    }
  }

  &.disabled {
    opacity: 0.5;
  }

  &.loading {
    align-items: flex-start;
    justify-content: flex-start;
    animation: pulse 1s alternate infinite;
  }

  &.sections {
    padding: 0;
    display: grid;
    grid-template-rows: repeat(auto-fit, minmax(2rem, 1fr));
  }

  &.sections.inline {
    grid-template-columns: repeat(auto-fit, minmax(5rem, 1fr));
    grid-template-rows: auto;
    grid-gap: 0;
  }
}

//
// Color variatons

@each $color, $value in map-merge($theme-colors, $provider-colors) {
  .card.#{$color} {
    color: $gray-0;
    background: $value;

    .title {
      color: $white;
    }

    @if ($color == "light") {
      color: $gray-60;

      .title {
        color: $gray-80;
      }
    }

    @if ($color == "dark") {
      background: $gray-60;
    }

    .help {
      opacity: 1;

      &:hover {
        opacity: 0.65;
      }
    }
  }
}

//
// Header

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $_gutter;
}

//
// Card footer

.footer {
  padding-top: $_gutter;
  margin-top: auto;
}

//
// Card section

.section {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1.5rem 0.5rem;

  &:not(:last-child) {
    border-bottom: 1px solid $gray-05;
  }
}

.card.inline .section {
  border-right: 0;

  &:not(:last-child) {
    border-bottom: 0;
    border-right: 1px solid $gray-05;
  }
}

//
// Content

.content {
  position: relative;
  z-index: 1;
}

//
// Help text icon & tooltip

.help {
  opacity: 0.4;
  transition: opacity 250ms;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  &.floating {
    position: absolute;
    top: 0.625rem;
    right: 0.625rem;
  }
}

//
// Title

.title {
  color: $gray-80;
  line-height: 1.75;
  font-weight: 700;
  font-size: 1.25rem;
  margin: 0;
  position: relative;
  z-index: 1;

  &.large {
    font-size: 1.875rem;
  }

  &.small {
    font-size: 1rem;
  }

  @media (min-width: $mobileBreakpoint) and (max-width: $mobileBreakpoint + 100px) {
    font-size: 1.15rem;
    line-height: 1.5;
  }

  @each $color, $value in $theme-colors {
    &.#{$color} {
      color: $value;
    }
  }
}

//
// Action button

.actionBtn {
  color: inherit;
  font-size: 1rem;
  white-space: nowrap;
  background: rgba($white, 0.2);
  border-radius: $border-radius-sm;
  padding: 2rem 1rem;
  width: 100%;
  transition: color 250ms, background 250ms;

  &:hover {
    color: $gray-80;
    background: rgba($white, 1);
  }

  &.iconOnly {
    width: 5rem;
    height: 5rem;
  }
}

// Card link
//

.link {
  transition: transform 250ms;
  height: 100%;

  &::after {
    content: "";
    opacity: 0;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    box-shadow: $shadow-4;
    transition: opacity 250ms;
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
  }

  &:hover {
    transform: scale(1.05);

    &::after {
      opacity: 1;
    }
  }
}

//
// Help modal

.helpModal {
  max-width: 50rem;
  padding: 1rem;
  width: 100%;
}
