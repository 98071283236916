@import "@/scss/variables";

.orderDetails {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: $gray-100;
  top: 0;
  left: 0;
  margin: 0;
  border-radius: 0;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2.5rem;
    color: $white;

    .left {
      display: flex;
      justify-content: space-between;
    }

    .title {
      margin: 0 2.5rem 0 0;
      font-size: 1.5rem;
    }

    .address {
      color: $primary;
      font-size: 1.25rem;
      font-weight: 600;
      display: flex;
      align-items: center;
      margin-right: 2.5rem;
    }
  }

  .actions {
    display: flex;
    justify-content: flex-start;
    overflow-x: auto;
    flex-shrink: 0;

    .actionBtn {
      padding: 2.5rem 1.375rem;
      flex-flow: column nowrap;
      background: $gray-60;

      &:hover:not([disabled]) {
        background: rgba($gray-40, 0.5);
      }

      &:not(:last-of-type) {
        margin-right: 1.25rem;
      }

      span {
        margin: 0;
      }

      svg {
        margin-bottom: 0.5rem;
      }
    }

    &::before,
    &::after {
      content: "";
      border-left: 20px solid transparent;
    }
  }

  .content {
    background-color: $white;
    border-radius: $border-radius-lg;
    flex-grow: 1;
    padding: 2.5rem;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr;
    margin: 1.25rem;
    overflow-y: auto;

    @media (min-width: $mobileBreakpoint) {
      grid-template-columns: 1fr 0.5fr;
    }

    .systemData,
    .timeline {
      margin-bottom: 2.5rem;
    }

    .systemData {
      display: flex;
      flex-direction: column;

      .details {
        display: grid;
        grid-gap: 0 2.5rem;
        grid-template-columns: minmax(13rem, 0.35fr) 1fr;
        align-content: start;
        margin: 0;

        @media (max-width: 800px) {
          grid-template-columns: 1fr;
        }

        dt {
          color: $gray-40;
        }

        dd {
          color: $gray-60;
        }

        dt,
        dd {
          padding: 1rem 0;
          font-size: 1rem;

          .spaced:not(:last-child) {
            border-right: 1px solid $gray-05;
            padding-right: 1rem;
            margin-right: 1rem;
          }
        }

        dd.invoice {
          display: flex;
          flex-direction: column;

          span:not(:last-child) {
            margin-bottom: 0.75rem;
          }
        }

        .signature {
          text-decoration: underline;
          cursor: pointer;
          color: $gray-60;
        }
      }
    }

    .timeline {
      display: flex;
      flex-direction: column;
      padding: 0;
    }

    .subtitle {
      margin: 0 0 2rem;
      font-size: 1.25rem;
      font-weight: bold;
    }
  }

  @media (min-width: $mobileBreakpoint) {
    .content {
      overflow-y: hidden;
    }

    .systemData {
      height: 100%;
      overflow: hidden;
    }

    .systemData .details,
    .timeline {
      height: 100%;
      overflow-y: auto;
    }
  }
}

@media (max-width: $mobileBreakpoint) {
  .orderTimeline {
    margin-bottom: 2.5rem;
  }
}

.signatureModal {
  .signatureImage {
    display: block;
    max-height: 20rem;
    border-radius: $border-radius-md;
    border: 2px solid $gray-05;
    padding: 0.5rem;
  }

  .signatureDownload {
    padding-top: 0;
  }
}
