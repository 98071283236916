@import "@deligoo/ui/src/scss/variables";

// https://chir.ag/projects/name-that-color/
$avatar-colors: (
  "blue-violet": #7260b6,
  "silver-tree": #60b68d,
  "malibu": #5d98ec,
  "porsche": #ecb35d,
  "cornflower-blue": #5d7cec,
  "heliotrope": #e15dec,
  "turquoise-blue": #5decca,
  "matrix": #b66060,
  "santa-fe": #b67a60,
  "apple": #52be2c,
  "zorba": #9d978f,
  "red-damask": #d87943,
);

$avatar-colors-bg: (
  "blue-violet": #ece6ff,
  "silver-tree": #e0f3ea,
  "malibu": #ebf3ff,
  "porsche": #fff4e4,
  "cornflower-blue": #e8edff,
  "heliotrope": #fde6ff,
  "turquoise-blue": #e6fffa,
  "matrix": #ffe6e6,
  "santa-fe": #f3e7e0,
  "apple": #e6fedd,
  "zorba": #f1efec,
  "red-damask": #ffece3,
);

.avatar {
  color: $gray-40;
  font-size: 1rem;
  font-weight: 800;

  width: 2.75rem;
  height: 2.75rem;
  border-radius: 50%;
  background: $gray-05;
  border: 2px solid #fff;
  box-shadow: 0 0 0 2px $gray-05;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

//
// Color variations

@each $color, $value in $avatar-colors {
  .#{$color} {
    background: map-get($avatar-colors-bg, $color);
    box-shadow: 0 0 0 2px map-get($avatar-colors-bg, $color);
    color: $value;
  }
}

@each $color, $value in $theme-colors {
  .#{$color} {
    background: map-get($theme-colors-alt, $color);
    box-shadow: 0 0 0 2px map-get($theme-colors-alt, $color);
    color: $value;
  }
}

//
// Dot indicator

.dot {
  &::before {
    content: "";
    width: 0.6875rem;
    height: 0.6875rem;
    border-radius: 50%;
    position: absolute;
    left: 1.875rem;
    bottom: 1.875rem;
    box-shadow: 0 0 0 3px #fff;
  }

  @each $color, $value in $theme-colors {
    &.dot-#{$color}::before {
      background: $value;
    }
  }
}

//
// Break indicator

.break {
  background-image: url("data:image/svg+xml,%3Csvg viewBox='-3 -3 14 14' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.823 5h3.354c.454 0 .822-.325.822-.726 0-.4-.368-.725-.822-.725H2.58L4.808 1.19C5.254.72 4.873 0 4.177 0H1.128C.674 0 .306.325.306.726c0 .4.368.725.822.725H2.42L.192 3.81C-.254 4.28.127 5 .823 5zm6.034 3h2.286c.473 0 .856-.346.856-.772 0-.426-.383-.771-.856-.771h-.45l1.106-1.19c.466-.5.07-1.267-.656-1.267H7.065c-.473 0-.856.346-.856.772 0 .426.383.771.856.771h.243L6.2 6.733C5.734 7.233 6.13 8 6.856 8z' stroke='white' stroke-width='6' stroke-linecap='round' stroke-linejoin='round' paint-order='stroke'%0Afill='%23ffa439' /%3E%3C/svg%3E");
  color: $warning;
  height: 1rem;
  width: 1rem;
  position: absolute;
  left: 1.75rem;
  bottom: 1.75rem;
}
