@import "@/scss/variables";

.distanceBar {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0.625rem 0;

  .label {
    color: $gray-60;
    font-size: 1rem;
    margin-left: 1.875rem;
    flex-shrink: 0;
  }

  .progress {
    overflow: hidden;
    background: $gray-05;
    border-radius: 1rem;
    position: relative;
    height: 0.4375rem;
    flex: 1 1 100%;

    &::before {
      content: "";
      width: var(--progress, 0%);
      height: 100%;
      border-radius: 1rem;
      position: absolute;
      background: $primary;
    }
  }

  @each $color, $value in $theme-colors {
    &.#{$color} {
      .label {
        color: $value;
      }

      .progress::before {
        background: $value;
      }
    }
  }
}
