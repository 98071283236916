@import "@/scss/variables";

.payments {
  background: $white;
}

.header {
  padding: 2.125rem 1.875rem 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  .title {
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
  }
}

//
// Table elements

.address {
  display: flex;
  align-items: center;

  .warningIcon {
    margin-left: 0.75rem;
  }
}

.driver {
  display: flex;
  align-items: center;
}

.status {
  display: flex;
  align-items: center;
  position: relative;

  svg {
    flex-shrink: 0;
  }

  .label {
    font-size: 0.5rem;
    text-transform: uppercase;
    font-weight: bold;
    transform: rotate(90deg);
    margin-left: 0.5rem;
  }
}
