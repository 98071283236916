@mixin reset-button {
  font-family: inherit;
  font-weight: inherit;
  background: transparent;
  cursor: pointer;
  padding: 0;
  border: 0;
}

@mixin tooltip-common {
  color: $gray-40;
  font-size: 0.625rem;
  font-weight: 600;
  background: $white;
  border-radius: 0.5rem;
  box-shadow: $shadow-4;
  padding: 0.5rem;
}

@mixin hide-scrollbar {
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin custom-scrollbar($color: $gray-40) {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  transition: scrollbar-color 250ms;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: $border-radius-sm;
  }

  &:hover {
    scrollbar-color: $color transparent;
  }

  &:hover::-webkit-scrollbar-thumb {
    background: $color;
  }
}
