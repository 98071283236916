@import "@/scss/variables";

.svgProgress {
  width: 100%;
  height: 100%;

  .bg,
  .value {
    stroke-width: 0.4rem;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  .bg {
    opacity: 0.06;
    stroke: #768099;
  }

  .value {
    stroke: $success;
    transform-origin: center;
    stroke-dasharray: 283;
    stroke-dashoffset: var(--offset, 280);
    transition: stroke-dashoffset 500ms linear;
  }

  &.loading {
    animation: svgAnimation 2s linear infinite;
    transform-origin: 50% 50%;

    .value {
      stroke-dashoffset: 280;
      animation: circleAnimation 3s ease-in-out infinite both;
    }
  }
}

@keyframes svgAnimation {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

@keyframes circleAnimation {
  0%,
  25% {
    stroke-dashoffset: 280;
    transform: rotate(0);
  }

  50%,
  75% {
    stroke-dashoffset: 75;
    transform: rotate(45deg);
  }

  100% {
    stroke-dashoffset: 280;
    transform: rotate(360deg);
  }
}
