@import "@/scss/variables";

.integrations {
  width: 100%;
  padding: 0 1.88rem 1.88rem;
  overflow: auto;

  display: grid;
  grid-gap: 1.88rem;
  align-content: start;
  grid-template-columns: repeat(auto-fill, minmax(20.75rem, 1fr));
}

.integration {
  padding: 2.5rem;

  .header {
    margin-bottom: 0.5rem;
  }

  .status,
  .workingHours {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .status {
    padding: 2rem 0;
  }

  .workingHours {
    border-top: 1px solid rgba(#fff, 0.3);
    padding: 2.5rem 0 0;

    span {
      font-weight: bold;
      font-size: 1.25rem;
      padding-left: 1rem;
    }
  }
}

.loading {
  min-height: 20rem;
}
