.gauge {
  position: relative;
  max-width: 100%;
  width: 6.25em;
  height: 4.5725em;
}

.icon {
  position: absolute;
  width: 1.875em;
  height: 1.875em;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -25%);
}

:export {
  defaultBackgroundColor: #dbdbe7;
}
