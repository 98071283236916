//
// Margin and paddings

$gutters: (
  0: 0,
  0\.5: 0.125rem,
  1: 0.25rem,
  1\.5: 0.375rem,
  2: 0.5rem,
  2\.5: 0.625rem,
  3: 0.75rem,
  3\.5: 0.875rem,
  4: 1rem,
  5: 1.25rem,
  6: 1.5rem,
  7: 1.75rem,
  8: 2rem,
  9: 2.25rem,
  10: 2.5rem,
  20: 5rem,
  32: 8rem,
  auto: auto,
);

@each $key, $value in $gutters {
  .m {
    &-#{$key} {
      margin: $value !important;
    }

    &t-#{$key} {
      margin-top: $value !important;
    }

    &r-#{$key} {
      margin-right: $value !important;
    }

    &b-#{$key} {
      margin-bottom: $value !important;
    }

    &l-#{$key} {
      margin-left: $value !important;
    }

    &x-#{$key} {
      margin: 0 $value !important;
    }

    &y-#{$key} {
      margin: $value 0 !important;
    }
  }

  .p {
    &-#{$key} {
      padding: $value !important;
    }

    &t-#{$key} {
      padding-top: $value !important;
    }

    &r-#{$key} {
      padding-right: $value !important;
    }

    &b-#{$key} {
      padding-bottom: $value !important;
    }

    &l-#{$key} {
      padding-left: $value !important;
    }

    &x-#{$key} {
      padding: 0 $value !important;
    }

    &y-#{$key} {
      padding: $value 0 !important;
    }
  }
}

//
// Display utils

.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

//
// Display flex utils

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.flex {
  &-wrap {
    flex-wrap: wrap;
  }

  &-grow-0 {
    flex-grow: 0;
  }

  &-grow-1 {
    flex-grow: 1;
  }

  &-shrink-0 {
    flex-shrink: 0;
  }

  &-shrink-1 {
    flex-shrink: 1;
  }
}

.align {
  &-start {
    align-items: flex-start;
  }

  &-center {
    align-items: center;
  }

  &-end {
    align-items: flex-end;
  }
}

.justify {
  &-start {
    justify-content: flex-start;
  }

  &-center {
    justify-content: center;
  }

  &-end {
    justify-content: flex-end;
  }

  &-between {
    justify-content: space-between;
  }

  &-around {
    justify-content: space-around;
  }

  &-evenly {
    justify-content: space-evenly;
  }
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cursor-pointer {
  cursor: pointer;
}
