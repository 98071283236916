@import "@/scss/variables";

.timeline {
  overflow-y: auto;
  padding-left: 0.25rem;

  &:not(:first-of-type) {
    border-top: 1px solid $gray-0;
  }
}

.step {
  color: $gray-40;
  text-align: left;
  position: relative;
  cursor: default;

  &:not(:last-of-type) {
    margin-bottom: 1.5rem;
  }

  .action {
    display: flex;
    flex-direction: column;
  }

  .action,
  .kind,
  .datetime {
    margin-left: 1.5rem;
  }

  .kind,
  .datetime {
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .datetime {
    font-size: 0.875rem;
  }

  .icon {
    width: 0.875rem;
    height: 0.875rem;
    margin-right: 0.5rem;
  }

  .kind {
    display: flex;
    align-items: center;
    letter-spacing: 0.4px;
    margin: 0.25rem 0;
    font-size: 1rem;
  }

  .content {
    flex: 1 1 100%;
    overflow: hidden;
  }

  .statusIcon::after {
    color: $gray-05;
    content: "";
    width: 2px;
    background: $gray-05;
    position: absolute;
    left: 0.0875rem;
    top: 50%;
  }

  &:first-child .statusIcon::after {
    height: 125%;
    transform: translateY(-10%);
  }

  &:last-child .statusIcon::after {
    height: 125%;
    transform: translateY(-110%);
  }

  &:not(:last-child):not(:first-child) .statusIcon::after {
    height: 175%;
    transform: translateY(-50%);
  }

  &:only-child .statusIcon::after {
    height: 0;
  }

  .kind::before {
    background-color: #fff;
    content: "";
    width: 0.3125rem;
    height: 0.3125rem;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 33%;
    box-shadow: 0 0 0 0.15625rem $gray-05;
  }
}

$timeline-colors: (
  "info": $info-50,
  "primary": $primary-50,
  "violet": #7973cb,
  "success": $success-50,
  "warning": $warning-50,
  "alert": $alert-50,
);

@each $color, $value in $timeline-colors {
  .timeline-#{$color} {
    color: $value !important;
  }
}
