@import "@/scss/variables";

.step {
  --rootsize: 1rem;
  color: $gray-40;
  font-size: calc(0.6875 * var(--rootsize));
  padding: 0 0 calc(1.5625 * var(--rootsize)) calc(0.875 * var(--rootsize));
  position: relative;

  .event {
    display: flex;
    margin: calc(0.25 * var(--rootsize)) 0;
  }

  .datetime {
    margin-bottom: calc(0.5 * var(--rootsize));
  }

  .name {
    font-size: calc(0.875 * var(--rootsize));
    line-height: 1.5;

    & + .param {
      margin-top: calc(0.375 * var(--rootsize));
    }
  }

  .param {
    font-size: calc(0.75 * var(--rootsize));
  }

  .icon {
    width: calc(1 * var(--rootsize));
    height: calc(1 * var(--rootsize));
  }

  .iconWrapper {
    margin: calc(0.2 * var(--rootsize)) calc(0.625 * var(--rootsize)) 0 0;
  }

  .iconWrapper::after {
    color: $gray-05;
    content: "";
    width: 2px;
    height: 100%;
    background: $gray-05;
    position: absolute;
    left: 0;
    top: 0;
  }

  .event::before {
    content: "";
    background: $white;
    width: calc(0.625 * var(--rootsize));
    height: calc(0.625 * var(--rootsize));
    border-radius: 50%;
    border: 2px solid $gray-05;
    transform: translateX(-50%) translateY(50%);
    position: absolute;
    left: 1px;
    z-index: 1;
  }
}
