@import "@/scss/variables";

.orderTracking {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: $white;
  top: 0;
  left: 0;
  margin: 0;
  border-radius: 0;

  .header,
  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2.5rem;
  }

  .header {
    .title {
      display: flex;
      align-items: center;
      margin: 0;
    }

    .uid {
      font-weight: normal;
      color: $primary;
      display: inline-flex;
      justify-content: space-between;
      align-items: center;

      &::before {
        content: "";
        margin: 0 1rem;
        background-color: $gray-05;
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
        display: inline-flex;
      }
    }
  }

  .footer {
    .address {
      flex: 1;
      font-size: 1rem;
    }

    .orderProgress {
      flex: 2;
      padding: 0 2rem;
    }

    .driver {
      flex: 1;
      display: flex;
      justify-content: center;
    }
  }

  .closeBtn {
    .icon:hover {
      color: $primary;
    }
  }
}
