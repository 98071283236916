@import "@/scss/variables";

.basket {
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    border-bottom: 1px solid $gray-0;
  }

  .dishes {
    display: flex;
    flex-direction: column;
    padding-bottom: 1.875rem;

    .dish {
      display: flex;
      flex-direction: column;

      &:not(:first-child) {
        margin-top: 1.875rem;
      }

      .dishInfo {
        display: flex;
        justify-content: space-between;
      }

      .packagingCost {
        display: flex;
        justify-content: space-between;
        font-size: 0.875rem;
        margin-top: 0.875rem;
        margin-left: 1.875rem;
      }

      .toppings {
        display: flex;
        flex-direction: column;
        font-size: 0.875rem;

        .topping {
          display: flex;
          justify-content: space-between;
          margin-top: 0.875rem;
        }

        .strike {
          text-decoration: line-through;
        }
      }
    }

    .quantity {
      display: inline-block;
      min-width: 1.875rem;
      font-size: 1.3rem;
      color: #ff2c52;
    }

    .currencyLabel {
      font-weight: 600;
      margin-left: 0.25rem;
    }
  }

  .notes {
    padding-top: 2.5rem;
    white-space: pre-line;
  }
}
