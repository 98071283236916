@import "@/scss/variables";

$_breakpoint: 500px;

.summary {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
}

.header {
  display: flex;
  align-items: center;
  margin: 2.5rem 2.5rem 0;
  flex-shrink: 0;

  .statusIcon {
    width: 6.25rem;
    height: 6.25rem;
    border-radius: 50%;
    background: $gray-05;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    .icon {
      width: 2rem;
      height: 2rem;
    }

    @each $color, $value in $theme-colors {
      &.#{$color} {
        color: $value;
        background: map-get($theme-colors-alt, $color);
      }
    }
  }

  .title {
    color: $black;
    font-weight: 700;
    font-size: 1.25rem;
  }

  .subtitle {
    color: $primary;
    font-size: 1.25rem;
  }

  .ordersCount {
    font-size: 0.75rem;
    line-height: 1.75;
    margin-left: auto;
  }

  @media (max-width: $_breakpoint) {
    flex-direction: column;
    justify-content: center;

    .ordersCount {
      order: -1;
    }
  }

  @media (min-width: $_breakpoint + 1px) {
    .statusIcon {
      margin-right: 1.875rem;
    }

    .subtitle::before {
      content: "";
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      background: $gray-05;
      display: inline-block;
      margin: 0 1rem;
    }
  }
}

.content,
.buffering,
.pending,
.assigned,
.completed {
  margin: auto;
  padding: 2.5rem;
  font-size: 0.875rem;
}

.buffering {
  text-align: center;
  max-width: 800px;
  color: $gray-60;

  .status {
    font-weight: 600;
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }

  .desc {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5;

    p {
      margin: 2.5rem;
    }
  }

  .time {
    margin-top: 2.5rem;
    margin-bottom: 0.3125rem;
  }
}

.pending {
  .about {
    font-size: 1rem;
    border-radius: 50%;
    background: $gray-0;
    position: relative;
    width: 16.75rem;
    height: 16.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .id {
    margin: 0.85rem 0;
  }

  .pendingSpinner {
    padding: 1rem;
    margin: 0;
    transform: translate(-50%, -50%);
    transform-origin: center;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
  }
}

.assigned {
  display: grid;
  grid-gap: 3rem;
  align-items: center;
  grid-template-columns: 1fr;
  margin: auto 0;

  @media (min-width: $mobileBreakpoint) {
    grid-template-columns: 1fr 2fr 1fr;
  }

  .transportType {
    position: relative;
    margin-bottom: 3rem;

    .landscape {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    .courier {
      width: 100%;
      height: 100%;
      padding: 2rem;
      object-fit: contain;
      position: absolute;
      bottom: -6rem;
      left: 0;
    }
  }

  .sectionTitle {
    margin-bottom: 1.5rem;
  }

  .courier,
  .time {
    display: grid;
    grid-template-rows: 1fr 1fr;
    padding: 1.5rem 0;

    .title {
      color: $black;
      font-size: 1.25rem;
      font-weight: 700;
    }

    .subtitle {
      color: $primary;
      font-size: 1rem;
      font-weight: 600;
      margin-top: 0.5rem;
    }
  }

  .courier {
    grid-template-columns: auto 1fr;

    .avatar {
      grid-row: span 2;
      font-size: 1.25rem;
      margin-right: 1.875rem;
      width: 4.375rem;
      height: 4.375rem;
    }
  }

  .time {
    grid-template-columns: 1fr auto;
    grid-auto-flow: column;

    .icon {
      grid-row: span 2;
      margin-left: 1.875rem;
      width: 3rem;
      height: 3rem;
    }
  }
}

.completed,
.canceled {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .checkCircle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    margin-bottom: 2rem;
  }
}

.completed {
  .checkCircle {
    background-color: $success-05;
  }
}

.canceled {
  .checkCircle {
    background-color: $alert-05;
  }
}

.downloadLabel[disabled] {
  pointer-events: none;
  opacity: 0.5;
}
