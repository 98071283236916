.modal {
  text-align: center;
  padding: 3.25rem;
  max-width: 36.25rem;
  width: 100%;
}

.icon {
  width: 3rem;
  height: 3rem;
  display: block;
  margin: 0 auto 1.25rem;
}
