@import "@deligoo/ui/src/scss/variables";

.inputWrapper {
  position: relative;
}

.input {
  color: $gray-80;
  font-size: 0.875rem;
  font-family: inherit;
  font-weight: 600;
  padding: 0.75rem 0;
  border: 0;
  border-bottom: 2px solid $gray-05;
  transition: border-color 250ms;
  box-sizing: border-box;
  width: 100%;

  &::placeholder {
    color: $gray-40;
  }

  &.plain {
    padding: 0.75rem 1rem;
    border-radius: $border-radius-sm;
    border: 2px solid $gray-05;

    &:focus {
      border-color: $primary;
    }
  }

  &.dark {
    color: #fff;
    background: $gray-80;
    border-color: $gray-80;

    &:focus {
      border-color: $gray-60;
    }
  }

  &.hasIconLeft {
    padding-left: 3rem;
  }

  &.hasIconRight {
    padding-right: 3rem;
  }

  &:read-only,
  &:read-only:focus,
  &[disabled] {
    color: $gray-40;
    background: $gray-0;
    border-color: $gray-05;
  }

  &:focus {
    outline: 0;
    border-color: $primary;
  }
}

//
// Input icons

.iconLeft,
.iconRight {
  width: 3rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  position: absolute;
  top: 0;

  svg {
    width: 1rem;
    height: 1rem;
  }
}

.iconLeft {
  left: 0;
}

.iconRight {
  right: 0;
}

//
// Size variations

.small {
  .input {
    font-size: 0.75rem;
    padding: 0.5rem 0;
    height: map-get($controlHeights, "small");
  }

  .hasIconLeft {
    padding-left: 1.875rem;
  }

  .hasIconRight {
    padding-right: 1.875rem;
  }

  .iconLeft,
  .iconRight {
    width: 1.875rem;

    svg {
      width: 0.6875rem;
      height: 0.6875rem;
    }
  }
}

.medium .input {
  height: map-get($controlHeights, "medium");
}

.large .input {
  font-size: 1rem;
  height: map-get($controlHeights, "large");
}

.extra-large .input {
  font-size: 1rem;
  height: map-get($controlHeights, "extra-large");
}
