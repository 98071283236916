@use "sass:math";

@import "@deligoo/ui/src/scss/variables";

.badge {
  font-weight: 500;
  border-radius: 2rem;
  display: inline-flex;
  align-items: center;
}

//
// Removable

.remove {
  color: currentColor;
  border: 0;
  padding: 0;
  margin-left: auto;
  background: unset;
  border-radius: 50%;

  .removeIcon {
    width: 0.45rem;
    height: 0.45rem;
    border-radius: 50%;
  }

  &:hover {
    color: #fff;
  }

  &:hover .removeIcon {
    box-shadow: 0 0 0 0.45rem $gray-100;
    background: $gray-100;
  }
}

//
// Action

.action {
  cursor: pointer;
  transition: opacity 250ms;

  &:hover {
    opacity: 0.85;
  }
}

//
// Sizes

$fontSizes: (
  "small": 0.75rem,
  "medium": 0.875rem,
  "large": 1rem,
  "extra-large": 1rem,
);

$gutters: (
  "small": 0.75rem,
  "medium": 0.875rem,
  "large": 1rem,
  "extra-large": 1rem,
);

@each $size, $value in $fontSizes {
  .#{$size} {
    font-size: $value;
    padding: 0.15rem $value;
    height: map-get($controlHeights, $size);

    .icon {
      margin-right: math.div($value, 1.5);

      svg {
        width: $value;
        height: $value;
      }
    }

    .remove {
      padding-left: $value;
    }
  }
}

//
// Color variations

@each $color, $value in $theme-colors {
  .#{$color} {
    &.solid {
      color: $white;
      background: $value;

      @if $color == "light" {
        color: map-get($theme-colors-alt, $color);
      }
    }

    &.outlined {
      color: $value;

      @if $color == "muted" {
        border-color: $gray-05;
      }
    }

    &.accented {
      color: $value;
      background: map-get($theme-colors-alt, $color);

      @if $color == "muted" {
        color: $gray-60;
        background: $gray-0;
      }

      @if $color == "dark" or $color == "light" {
        background: $value;
        color: map-get($theme-colors-alt, $color);
      }
    }
  }
}

@each $color, $value in $status-colors {
  .status-#{$color} {
    composes: badge;
    color: $value;
    background: lighten($value, 33%);
  }
}

//
// Style variations

.outlined {
  border: 1px solid currentColor;
}
