@import "@/scss/variables";

.receiveOrder {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: $white;
  top: 0;
  left: 0;
  margin: 0;
  border-radius: 0;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3.75rem;

    .left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .right {
      display: flex;
      justify-content: space-between;
    }

    .title {
      margin: 0 2.5rem 0 0;
      font-size: 1.5rem;
    }

    .address {
      color: $primary;
      font-size: 1rem;
      font-weight: 600;
      display: flex;
      align-items: center;
      margin-top: 1rem;
      margin-right: 2.5rem;
    }
  }

  .content {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    padding: 0 1.25rem 3.75rem;
    font-size: 1rem;
    overflow-y: auto;

    @media (max-width: 959px) {
      flex-direction: column;
    }

    .left,
    .right {
      padding: 0 2.5rem;
      width: 100%;
    }

    .distance {
      padding-bottom: 1rem;
      margin-bottom: 1.25rem;
      border-bottom: 1px solid $gray-0;
    }

    .summary {
      .section {
        display: flex;

        padding: 1.875rem 0;

        &:first-child {
          padding-top: 0;
        }

        &:not(:last-child) {
          border-bottom: 1px solid $gray-0;
        }
      }

      .client {
        display: flex;
        flex-direction: column;

        .top {
          display: flex;
          flex-grow: 1;
          justify-content: space-between;
          cursor: pointer;

          .title {
            display: inline-flex;
            align-content: baseline;
            flex-grow: 1;
          }

          .expander {
            width: auto;
            text-align: right;
          }
        }

        .bottom {
          .item {
            display: flex;
            justify-content: space-between;
            margin-top: 1rem;
          }
        }
      }

      .deliveryInfo {
        display: flex;
        flex-direction: column;
        color: $gray-40;

        .deliveryMethod {
          display: flex;
          justify-content: space-between;
        }

        .orderComment {
          margin-top: 1.75rem;
        }
      }

      .payment {
        flex-direction: column;
        justify-content: space-between;

        .top {
          display: flex;
          flex-grow: 1;
          justify-content: space-between;
          align-items: center;

          &:disabled {
            color: inherit;
            cursor: default;
          }
        }

        .bottom {
          .item {
            display: flex;
            justify-content: space-between;
            margin-top: 1rem;
          }
        }

        .form,
        .document {
          display: flex;
          flex-direction: column;

          .label {
            display: inline-flex;
            align-items: center;
          }
        }

        .form.document {
          display: flex;
          align-items: center;
          flex-direction: row;
          flex-grow: 1;
          justify-content: space-between;
        }

        .label {
          color: $gray-40;
          margin-bottom: 0.75rem;
        }

        .expander {
          margin-left: 1.5rem;
        }
      }

      .pricing {
        flex-direction: column;

        .item {
          display: flex;
          justify-content: space-between;

          .value {
            color: $gray-40;
          }

          .currency {
            font-weight: 600;
          }

          &:not(:last-child) {
            margin-bottom: 1.875rem;
          }
        }
      }
    }
  }

  .actions {
    display: flex;

    .button {
      border-radius: 0;
      flex: auto;
    }
  }
}
