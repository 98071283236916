@import "@/scss/variables";

.appUpdateToast {
  max-width: 25rem;
}

.info {
  line-height: 1.5;
  color: $gray-100;
}

.updateButton {
  margin-top: 0.75rem;
}
