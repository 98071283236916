@import "@/scss/variables";

.ordersToReceiveList {
  overflow: auto;
  background: #fff;
  width: 100%;

  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.header {
  padding: 2.125rem 1.875rem 0;
  display: flex;
  overflow-x: auto;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;

  .title {
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
  }
}

.pulsating {
  animation: pulse 1s infinite alternate;
  // border-bottom: 0.125rem solid white;
}

@keyframes pulse {
  from {
    background-color: inherit;
  }

  to {
    background-color: $success-05;
  }
}
