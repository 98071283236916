/* stylelint-disable selector-max-type -- This is not a module */
@import "variables";

* {
  @include custom-scrollbar;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

html {
  @media (max-width: 500px) {
    font-size: 70%;
  }

  @media (min-width: 501px) and (max-width: 1100px) {
    font-size: 80%;
  }

  @media (min-width: 1100px) and (max-height: 740px) {
    font-size: 90%;
  }
}

input[type="number"] {
  appearance: textfield;
}

.page-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;
}

.quantity-1 {
  color: #768099 !important;
}

.floatRight {
  float: right;
  margin-left: 5px;
}
