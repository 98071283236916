@import "@deligoo/ui/src/scss/variables";

.wrapper {
  position: relative;
}

.label {
  display: flex;
  cursor: pointer;

  &:hover .checkbox {
    background: $gray-0;
  }
}

.checkbox {
  color: transparent;
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background: $white;
  margin-right: 0.75rem;
  border-radius: 0.25rem;
  border: 0.125rem solid $gray-05;
  transition: background-color $transition, border-color $transition;

  &:last-child {
    margin-right: 0;
  }

  .icon {
    width: 50%;
    height: 50%;
    min-width: 0.5rem;
    min-height: 0.5rem;
  }
}

.input {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  bottom: 0;

  &:checked {
    & + .label .checkbox {
      color: $white;
      background: $success-50;
      border-color: $success-50;
    }

    & + .label:hover .checkbox {
      background: lighten($success, 10);
      border-color: lighten($success, 10);
    }
  }

  &:disabled {
    & + .label {
      color: $gray-60;
      cursor: default;
    }

    & + .label .checkbox {
      background: $gray-0;
      border-color: $gray-05;
    }
  }
}

.wrapper + .wrapper {
  margin-left: 2rem;
}
