@import "@/scss/variables";

$_mobileBreakpoint: 800px;
$_desktopBreakpoint: 1100px;

.weight {
  flex-shrink: 0;
  margin-left: 1rem;

  .weightField {
    color: $gray-40;
    font-size: 40px;
    font-weight: 400;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .weightInput {
    font-size: inherit;
    text-align: center;
    margin-right: 0.625rem;
    width: 12rem;
  }
}

.packageType {
  &.default {
    display: grid;
    grid-gap: 2.5rem;

    @media (min-width: $mobileBreakpoint) {
      grid-template-columns: 2fr 1fr 1fr 1fr;
    }
  }

  &.hebe,
  &.ecommerce {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    grid-gap: 1.25rem;

    .about {
      display: flex;
      align-items: center;
      justify-content: space-between;
      grid-column: 1 / -1;
    }

    .header {
      max-width: 18.5rem;
    }

    @media (min-width: $_desktopBreakpoint) {
      grid-template-columns: repeat(5, 1fr);
    }
  }

  &.hebe {
    .about {
      margin-bottom: 1.25rem;
    }
  }

  &.ecommerce {
    grid-gap: 2.5rem;
    grid-template-rows: auto 1fr;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));

    @media (min-width: $_desktopBreakpoint) {
      grid-template-columns: repeat(3, 1fr);

      .package {
        padding-top: 3.75rem;
        padding-bottom: 3.75rem;
      }
    }

    .name span {
      color: $primary;
      display: block;
    }

    .desc {
      font-size: 1.5rem;
      margin-bottom: 3.75rem;
    }
  }
}

.package {
  text-align: center;
  min-height: 15rem;
  padding: 1.5rem 0.5rem;
  border-radius: 0.75rem;
  border: 1px solid $gray-05;
  transition: border-color 250ms;
  position: relative;
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;

  .name {
    font-size: 0.875rem;
  }

  .desc {
    color: $gray-100;
    font-size: 1rem;
    font-weight: 700;
    margin: 1rem 0 2rem;
  }

  .packageImg {
    margin-top: auto;
  }

  .controls {
    display: flex;
    align-items: center;
    background: $white;
    min-width: 7rem;
    border-radius: 6.25rem;
    box-shadow: 0 0.75rem 1.875rem rgba(26, 26, 26, 0.16);
    transform: translateX(-50%);
    position: absolute;
    bottom: 2.5rem;
    left: 50%;
    transition: opacity 250ms;
    pointer-events: none;
    opacity: 0;

    .control {
      font-weight: bold;
      margin: 0.35rem;
      border-radius: 50%;
    }

    .value {
      color: $gray-100;
      font-size: 1rem;
      margin: 0 0.5rem;
      flex-grow: 1;
    }

    &.active {
      opacity: 1;
      pointer-events: all;
    }
  }

  &:hover,
  &:focus,
  &.outlined {
    border-color: $primary;
    box-shadow: 0 0 0 2px $primary;
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;
  }
}

.prices {
  display: grid;
  grid-gap: 2.5rem;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 1rem;

  @media (max-width: $mobileBreakpoint) {
    .price {
      grid-column: span 2;
    }
  }

  @media (min-width: $mobileBreakpoint) {
    grid-template-columns: 1fr 10rem 10rem;

    .section {
      min-height: 10rem;
    }
  }

  @media (min-width: 1920px) {
    display: flex;

    .section:not(:last-child) {
      margin-right: 2.5rem;
    }
  }

  .section {
    display: flex;
    flex-direction: column;
  }

  .header {
    margin: 0;
    white-space: nowrap;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: inherit;
    margin: 1rem 0;
    flex-grow: 1;
  }

  .totalPrice {
    text-align: center;
    justify-content: center;
  }
}

.paymentOptions {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  flex-grow: 1;

  @media (min-width: $mobileBreakpoint) {
    min-height: 5rem;
  }

  .buttonLabel {
    padding-right: 0.5rem;
  }

  button {
    align-items: center;
    flex-wrap: nowrap;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    flex: 1 1 100%;

    span:last-of-type {
      display: flex;
      align-items: center;

      & > *:not(:last-child) {
        border-right: 1px solid $gray-0;
        margin-right: 0.5rem;
      }

      .amount > *:first-child {
        border: none;
      }

      .currency {
        font-size: 0.75rem;
        pointer-events: none;
      }

      .input {
        font-weight: bold;
        background: transparent;
        padding: 0;
        border: none;
        text-align: right;
        width: 4rem;

        &::selection {
          background: $secondary;
        }

        &::placeholder {
          color: $white;
        }
      }
    }
  }

  @media (min-width: $mobileBreakpoint) {
    button {
      flex: 0 0 auto;
    }

    button:not(:last-of-type) {
      margin-right: 1rem;
    }

    .error {
      position: absolute;
      bottom: -1rem;
    }
  }
}

.inputWrapper {
  position: relative;

  .currency {
    font-size: 0.75rem;
    pointer-events: none;
    transform: translateY(-50%);
    position: absolute;
    right: 1rem;
    top: 50%;
  }
}

.transportTypes {
  margin-top: 3.375rem;

  .transportType {
    margin-right: 1.25rem;
    pointer-events: none;
  }
}
