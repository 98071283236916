@import "@/scss/variables";

.alert {
  flex-shrink: 0;
  color: #fff;
  font-weight: bold;
  font-size: 1.125rem;
  text-align: center;
  padding: 1.5rem;
  transition: background 250ms;
  z-index: 2;
  position: sticky;
  top: 0;

  &.success {
    background: #69d337;

    &:hover {
      background: lighten(#69d337, 5%);
    }
  }

  &.warning {
    background: #ffbf35;

    &:hover {
      background: lighten(#ffbf35, 5%);
    }
  }

  &.danger {
    background: $alert;

    &:hover {
      background: lighten($alert, 5%);
    }
  }

  .content {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &.disposable {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;

    .content {
      margin-right: 1rem;
    }
  }
}

.modal {
  min-width: 40rem;
}
