@import "@/scss/variables";

.backdrop {
  z-index: 2;
}

.navbarWrapper {
  z-index: 3;
  overflow: hidden;
  max-height: 100vh;
  position: sticky;
  top: 0;
}

.navbar {
  color: #fff;
  display: flex;
  align-items: center;
  background: $gray-100;
  overflow-x: hidden;
  overflow-y: auto;

  &.expanded {
    min-width: 15rem;
    box-shadow: $shadow-5;
    position: fixed;
    top: 0;

    .label {
      display: block;
    }

    .toggleBtn {
      transform: rotate(180deg);
    }
  }

  @media (min-width: $mobileBreakpoint) {
    flex-direction: column;
    max-width: 16rem;
    height: 100%;
  }
}

.logotype {
  flex-shrink: 0;
  user-select: none;
  transition: opacity 250ms;
  padding: 1rem 1.625rem;

  img {
    width: 100%;
    height: 1.75rem;
    object-fit: contain;

    &.hebe,
    &.inpost {
      height: 3rem;
    }
  }

  &:hover {
    opacity: 0.75;
  }

  @media (min-width: $mobileBreakpoint) {
    padding: 1.875rem 1.625rem;

    &.hebe,
    &.inpost {
      padding: 1.875rem 1rem;

      img {
        height: 3rem;
      }
    }
  }
}

.nav {
  flex: 1 1 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0 1.5rem;
  min-width: 6.25rem;
  width: 100%;

  @media (min-width: $mobileBreakpoint) {
    justify-content: center;
    flex-direction: column;
  }
}

.navLink {
  color: $gray-40;
  padding: 2rem 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &.isActive {
    color: $primary;
  }

  &:hover {
    color: $white;
  }

  .icon {
    min-width: 3rem;
    flex-shrink: 0;
    margin: auto;
  }

  .label {
    flex: 1 1 100%;
    margin-left: 1rem;
    display: none;
  }
}

.footer {
  padding: 1.875rem 0;

  .toggleBtn {
    width: 3rem;
    height: 3rem;
    border-radius: $border-radius-md;
    border: 1px solid rgba($gray-40, 0.35);
    transition: border-color 250ms, background 250ms;

    &:hover {
      background: rgba($primary, 0.085);
      border-color: $primary;
    }
  }

  @media (max-width: $mobileBreakpoint) {
    display: none;
  }
}
