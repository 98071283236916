@import "@/scss/variables";

.alert {
  color: #fff;
  font-size: 0.875rem;
  font-weight: 600;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  min-height: 3.25rem;
  display: flex;
  align-items: center;

  .iconWrapper {
    color: $alert-05;
    width: 2.25rem;
    height: 2.25rem;
    margin-right: 0.625rem;
    border: 2px solid;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@each $color, $value in $theme-colors {
  .#{$color} {
    background: $value;
  }
}
