//
// Grayscale:

$white: #fff;
$black: #000;

$gray-100: #181e2e;
$gray-80: #1f273a;
$gray-60: #394257;
$gray-40: #768099;
$gray-20: #a9b3cc;
$gray-05: #e8eef3;
$gray-0: #f1f4f8;

//
// Primary:

$primary-50: #56d1ee;
$primary-20: #83e7ff;
$primary-10: #b0f0ff;
$primary-05: #d4f7ff;
$primary-0: #edfcff;

$primary: $primary-50;

//
// Secondary:

$secondary-50: #68a3ed;
$secondary-20: #90c2ff;
$secondary-10: #c2ddff;
$secondary-05: #e2efff;
$secondary-0: #f2f8ff;

$secondary: $secondary-50;

//
// Support:

$success-50: #69d337;
$success-05: #e0fed3;
$success: $success-50;

$warning-50: #ffa439;
$warning-05: #ffe4c3;
$warning-01: #95774b;

$warning: $warning-50;

$alert-50: #ff2c52;
$alert-05: #fcdae0;
$alert: $alert-50;

$danger-50: #70201f;
$danger-05: lighten($danger-50, 60%);
$danger: $danger-50;

$info-50: #519cf3;
$info-05: #b9d9ff;
$info: $info-50;

$accent-50: #7973cb;
$accent-05: lighten($accent-50, 25%);
$accent: $accent-50;

//
// Theme colors:

$gray-colors: (
  "gray-100": $gray-100,
  "gray-80": $gray-80,
  "gray-60": $gray-60,
  "gray-40": $gray-40,
  "gray-20": $gray-20,
  "gray-05": $gray-05,
  "gray-0": $gray-0,
);

$theme-colors: (
  "primary": $primary-50,
  "secondary": $secondary-50,
  "success": $success-50,
  "warning": $warning-50,
  "alert": $alert-50,
  "danger": $danger-50,
  "info": $info-50,
  "accent": $accent-50,
  "muted": $gray-40,
  "light": $gray-05,
  "dark": $gray-80,
);

$theme-colors-alt: (
  "primary": $primary-05,
  "secondary": $secondary-05,
  "success": $success-05,
  "warning": $warning-05,
  "alert": $alert-05,
  "danger": $danger-05,
  "info": $info-05,
  "accent": $accent-05,
  "muted": $gray-05,
  "light": $gray-80,
  "dark": $gray-05,
);

$status-colors: (
  "pending": $gray-40,
  "assigned": $warning-01,
  "published": $warning-50,
  "started": $info-50,
  "in_progress": $accent-50,
  "finished": $success-50,
  "completed": $success-50,
  "canceled": $alert-50,
  "failed": $alert-50,
);
