@import "@/scss/variables";

.receiveOrder {
  color: $white;
  width: 100%;
  height: 100%;
  background: $gray-100;
  padding: 0 1.25rem 1.25rem;
  display: flex;
  flex-direction: column;
  cursor: default;

  .header {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2.625rem;
    overflow: auto;

    .title {
      font-size: 1.5rem;
      font-weight: 700;
      padding: 2.625rem 0;
      margin: 0;
    }
  }

  .content {
    color: $gray-40;
    font-size: 1rem;
    background: $white;
    border-radius: 1.25rem;
    flex: 1 1 100%;
    overflow-y: auto;
    padding: 2.5rem;
    display: flex;
    flex-direction: column;
  }

  .main {
    position: relative;
    flex: 1 1 100%;
  }

  fieldset {
    border: 0;
    padding: 0;

    & + fieldset {
      margin-top: 3.75rem;
    }
  }

  .contactDetailsForm {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    margin-top: 2rem;

    @media (min-width: 800px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

.actions {
  display: grid;
  gap: 1.875rem;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 1.875rem;
}

.json {
  color: $gray-60;
  font-size: 1rem;
  font-weight: 400;
  background: $gray-0;
  padding: 1rem;
  border-radius: 0.75rem;
  margin: 0;
  overflow-y: auto;
}
