@import "@/scss/variables";

.alert {
  margin-bottom: 1rem;

  @media (min-width: 700px) {
    margin: unset;
    position: absolute;
    top: 1.875rem;
    right: 2.5rem;
  }
}

.timeBoxes {
  padding: 1.25rem 0;
  display: grid;
  grid-gap: 0.25rem;
  grid-template-columns: repeat(auto-fit, minmax(4.5rem, 1fr));

  .timeBox {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    line-height: 1.25;
    height: 6.5rem;
    background: $primary-0;
    transition: color 250ms, background 250ms, opacity 250ms;
    cursor: pointer;

    &:hover {
      background: $primary-05;
    }

    .minutes {
      color: $black;
    }

    .label {
      color: $gray-40;
    }

    &.selected {
      background: $primary-50;

      .minutes,
      .label {
        color: $white;
      }
    }

    &.disabled {
      cursor: default;
      opacity: 0.25;
    }

    &:first-child {
      border-radius: 0.75rem 0 0 0.75rem;
    }

    &:last-child {
      border-radius: 0 0.75rem 0.75rem 0;
    }
  }
}

.options {
  display: flex;

  & > div {
    padding-left: 2rem;
  }

  .timingMethodSwitch {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;

    margin-left: 4rem;
    margin-right: 2rem;

    width: 50%;

    button {
      border: 1px solid $gray-0;
      margin-bottom: 5px;

      &:first-of-type {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        //border-bottom: none;
      }

      &:last-of-type {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        //border-top: none;
      }

      &:only-child {
        border-radius: 0.375rem;
        border-top: 1px solid $gray-0;
        //border-bottom: 1px solid $gray-0;
      }
    }
  }
}

.deliveryTime {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1.25rem;
  width: 50%;

  .deliveryMethodSwitch {
    display: flex;
    flex-direction: column;
    margin-right: 5rem;
    width: 9rem;

    button {
      border: 1px solid $gray-0;

      &:first-of-type {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: none;
      }

      &:last-of-type {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-top: none;
      }

      &:only-child {
        border-radius: 0.375rem;
        border-top: 1px solid $gray-0;
        border-bottom: 1px solid $gray-0;
      }
    }
  }

  .inputs {
    color: $gray-100;
    font-size: 2.5rem;

    .wrapper {
      display: flex;
      align-items: center;
    }

    .input {
      width: 13rem;
      padding: 0;
      font-size: inherit;
      font-weight: bold;
      text-align: center;
      vertical-align: text-bottom;
      appearance: none;
      background: #fff;
    }

    .error {
      margin-left: 2rem;
    }

    .separator {
      color: $gray-05;
      margin: 0 1rem;
    }
  }
}

.ready {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;

  .readyIcon {
    width: 6.25rem;
    height: 6.25rem;
    border-radius: 50%;
    color: $success;
    background: $success-05;
    margin-bottom: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.timeRanges {
  .title {
    margin-bottom: 2.5rem;
  }

  .ranges {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(12.5rem, 1fr));
    grid-auto-rows: 10rem;
    grid-gap: 2.5rem;
    margin-top: 2.5rem;

    .rangeButton {
      color: $primary;
      font-size: 1.75rem;
      font-weight: bold;
      background: $primary-0;
      border-radius: 0.75rem;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      transition: background 250ms, color 250ms;

      &:hover {
        background: $primary-05;
      }

      &.selected {
        color: $white;
        background: $primary;
      }
    }
  }
}
