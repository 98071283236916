@import "@deligoo/ui/src/scss/variables";

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

$spinner-sizes: (
  "small": 0.75rem,
  "medium": 1rem,
  "large": 1.25rem,
  "extra-large": 2.5rem,
);

.spinner {
  margin: auto;
  position: relative;
  border-width: 0.25rem;
  border-style: solid;
  border-color: rgba($gray-100, 0.2);
  border-right-color: $gray-100;
  border-radius: 50%;
  transform: translateZ(0);
  animation: spin 1s infinite linear;

  @each $size, $value in $spinner-sizes {
    &.#{$size} {
      width: $value;
      height: $value;
    }
  }

  @each $color, $value in $theme-colors {
    &.#{$color} {
      border-color: rgba($value, 0.2);
      border-left-color: $value;
    }
  }
}

.inline {
  display: inline-block;
  margin: 0;
}
