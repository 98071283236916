@import "@/scss/variables";

.ordersToReceive {
  background: $white;
}

.header {
  padding: 2.125rem 1.875rem 0;
  display: flex;
  overflow-x: auto;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;

  .title {
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
  }
}
