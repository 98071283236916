.refundEvent {
  display: flex;
  flex-direction: column;
}

.icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.refundFor {
  text-align: center;
  margin-top: 0.5rem;
}
