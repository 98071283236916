.price {
  font-weight: 700;

  span {
    text-transform: uppercase;
    font-weight: normal;
    font-size: 0.65em;
    opacity: 0.75;
  }
}
