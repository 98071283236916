@import "@/scss/variables";

.settings {
  background: $white;
}

.logoutButton {
  margin-left: auto;
}

.section {
  display: flex;
  flex-direction: column;
  padding: 1.875rem 0;
  margin: 0 1.875rem;

  &:not(:last-child) {
    border-bottom: 1px solid $gray-05;
  }

  .header {
    font-weight: 600;
    font-size: 1.25rem;
    margin: 0 0 1.875rem;
  }

  .inputGroup {
    display: grid;
    grid-gap: 1.875rem;

    @media (min-width: $mobileBreakpoint) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .saveButton {
    margin: 1.75rem 1rem 0 0;
  }
}

.section.row {
  .header {
    margin: 0;
  }

  min-height: 7rem;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
}
