//
// Design system typography

%heading {
  color: $gray-100;
  line-height: 1.5;
  margin: 0;
}

.h900 {
  @extend %heading;
  font-size: 4.5rem;
  font-weight: 700;
}

.h800 {
  @extend %heading;
  font-size: 3.5rem;
  font-weight: 700;
}

.h700 {
  @extend %heading;
  font-size: 2.5rem;
  font-weight: 700;
}

.h600 {
  @extend %heading;
  font-size: 2rem;
  font-weight: 700;
}

.h500 {
  @extend %heading;
  font-size: 1.5rem;
  font-weight: 700;
}

.h400 {
  @extend %heading;
  font-size: 1.25rem;
  font-weight: 700;
}

.h300 {
  @extend %heading;
  font-size: 1rem;
  font-weight: 600;
}

.h200 {
  font-size: 0.875rem;
  font-weight: 600;
}

.h100 {
  font-size: 0.75rem;
  font-weight: 600;
}

.subtitle {
  font-size: 1.25rem;
  font-weight: 400;
}

.text {
  &-h {
    @extend %heading;
  }

  &-large {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
  }

  &-medium {
    font-size: 0.8125rem;
    font-weight: 600;
  }

  &-small {
    font-size: 0.75rem;
    font-weight: 400;
  }

  &-p {
    font-size: 0.8125rem;
    font-weight: 600;
  }

  &-desc {
    font-size: 0.6875rem;
    font-weight: 600;
  }

  &-tag {
    font-size: 0.5rem;
    font-weight: 800;
    text-transform: uppercase;
  }

  &-error {
    color: $alert;
    font-size: 0.75rem;
    font-weight: 700;
    margin-top: 0.25rem;
  }

  &-left {
    text-align: left;
  }

  &-center {
    text-align: center;
  }

  &-right {
    text-align: right;
  }
}

.font-weight {
  &-thin {
    font-weight: 400;
  }

  &-base {
    font-weight: 600;
  }

  &-bold {
    font-weight: 700;
  }
}

.def-list {
  margin: 0;

  dt {
    color: $gray-40;
    font-size: 0.75rem;
    font-weight: 700;
    display: block;

    &:not(:first-of-type) {
      margin-top: 0.75rem;
    }
  }

  dd {
    color: $gray-60;
    font-size: 0.875rem;
    display: block;
    margin: 0;
  }
}

//
// Text utils

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.no-wrap {
  white-space: nowrap;
}

.truncate {
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;
}

//
// Disabled link

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

//
// Color variations

@each $color, $value in map-merge($theme-colors, $gray-colors) {
  .text-#{$color} {
    color: $value !important;
  }
}

@each $color, $value in $status-colors {
  .status-#{$color} {
    color: $value !important;
  }
}
