@import "@/scss/variables";

$_mobileBreakpoint: 800px;
$_desktopBreakpoint: 1100px;

.phone {
  display: grid;
  grid-gap: 2.5rem;
  align-items: center;
  grid-template-columns: auto 1fr;
  padding: 2.5rem 2.5rem 0;

  label {
    font-size: 1rem;
    margin: 0;
  }
}

.addressListBtn {
  margin-top: 1.75rem;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.summary {
  min-height: 7.375rem;
  margin: 1.75rem 0 0;
  display: grid;
  grid-gap: 1rem;

  @media (min-width: $_mobileBreakpoint) {
    grid-gap: 0;
    grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));

    .item {
      display: grid;
      grid-template-rows: 1fr 1fr;
      align-items: center;
      padding: 0 3.125rem;

      &:first-of-type {
        padding-left: 0;
      }

      &:not(:last-child) {
        border-right: 1px solid $gray-05;
      }
    }
  }
}

.addressList {
  flex: 1 1 100%;
  margin: 2.5rem 0;
  display: flex;
  flex-direction: column;

  .title {
    color: $black;
    font-size: 1rem;
    font-weight: 600;
    margin: 0 2.5rem 0.625rem;
  }

  .list {
    --gutter: 2.5rem;
  }
}

.addressForm {
  display: grid;
  grid-gap: 2.5rem;
  grid-template-columns: 1fr;

  @media (min-width: $mobileBreakpoint) {
    grid-template-columns: repeat(12, 1fr);

    @for $i from 1 through 12 {
      .col#{$i} {
        grid-column: span $i;
      }
    }
  }
}
