@import "@/scss/variables";

.header {
  padding: 4rem 2rem 2.5rem;
  border-bottom: 1px solid $gray-05;

  .wrapper {
    text-align: center;
    max-width: $desktopBreakpoint;

    .logo {
      width: 9.25rem;
    }

    @media (min-width: $mobileBreakpoint) {
      display: flex;
      margin: 0 auto;
      justify-content: space-between;

      .logo {
        width: 13.25rem;
      }
    }
  }

  .title {
    color: $gray-80;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 2rem;
  }
}

.sections {
  padding: 0 2rem;
}

.section {
  display: flex;
  margin: 2rem auto;
  align-items: center;
  flex-direction: column;
  text-align: center;

  max-width: $desktopBreakpoint;

  @media (min-width: $mobileBreakpoint) {
    text-align: left;
    flex-direction: row;
  }
}

.icon {
  width: 12.625rem;
  height: 12.625rem;
  border-radius: 50%;
}

.button {
  width: 100%;
  margin: 1.2rem 0;
  padding: 0 2rem;
  border: 1px solid $gray-0;

  @media (min-width: $mobileBreakpoint) {
    margin: 0;
    width: fit-content;
  }
}

.info {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  font-weight: 600;

  .step {
    color: $gray-40;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .title {
    color: $gray-80;
    font-size: 1.25rem;
    line-height: 2rem;

    padding: 0.5rem 0 1.25rem;
  }

  .description {
    color: $gray-40;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  @media (min-width: $mobileBreakpoint) {
    margin: 0 2rem;
  }
}
