@import "@/scss/variables";

.form {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;

  .formInputs {
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .input {
    font-size: 1.5rem;
    font-weight: 500;
    border-width: 3px;
    padding: 2rem 0;
    margin: 1rem 0;
  }

  .action {
    font-size: 1.25rem;
    margin-top: 4rem;
    height: 4.25rem;
  }

  @media (min-width: 800px) {
    width: 60vw;
    padding-right: 3rem;
  }
}

.figure {
  display: none;

  @media (min-width: 800px) {
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 40vw;
    z-index: -1;
  }
}

.getApps {
  height: 4.25rem;
  padding: 1.375rem;
  text-align: center;
  margin-top: 1.25rem;
  border-color: $gray-0;
}
